/* DON'T EDIT THIS FILE: edit original and run build again */ import { SimpleDropdownValueOptions } from "../../../credit/ui/form/simple-dropdown.tsx";
import {
  BaseLiveField,
  LiveFieldSpec,
} from "../../../framework/live-form/field-spec.ts";
import { LiveData } from "../../../framework/live-form/types.ts";
import { singleOptionLiveFieldType } from "./type.ts";

type SingleOptionVariant = "dropdown" | "radioButtons" | "comboBox";

export type SingleOptionLiveFieldSpec<TExtraLiveData> =
  LiveFieldSpec<TExtraLiveData> & {
    valueOptions: SimpleDropdownValueOptions;
    variant?: SingleOptionVariant;
  };

export class SingleOptionLiveField<
  TExtraLiveData
> extends BaseLiveField<TExtraLiveData> {
  private valueOptions: SimpleDropdownValueOptions;
  private variant: SingleOptionVariant;

  constructor(spec: SingleOptionLiveFieldSpec<TExtraLiveData>) {
    super(spec, singleOptionLiveFieldType);
    this.valueOptions = spec.valueOptions;
    this.variant = spec.variant ?? "dropdown";
  }

  isEmpty(liveData: LiveData<TExtraLiveData>) {
    return !this.getValue(liveData);
  }

  dontUseLabelTag() {
    return this.variant === "radioButtons";
  }

  getValueOptions() {
    return this.valueOptions;
  }

  getVariant() {
    return this.variant;
  }
}
