/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  ComponentImporter,
  ComponentImporterNoProps,
} from "../../../framework/theme/component-importer-type.ts";
import {
  emailChannelType,
  webHomeChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { FunctionComponent } from "react";
import onboardingTermsOfUseTitle from "./onboarding-terms-of-use-title.ts";

class MediterraneaOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  useSnailMailViaEmail(): boolean {
    return true;
  }

  getSnailMailSignatureAddress(): string | null {
    return "San Jeronimo 177 Piso 10 OF AB";
  }

  hasAune(): boolean {
    return true;
  }

  requiresAcceptingCommissions(): boolean {
    return true;
  }

  commissionsImporter: ComponentImporterNoProps = () => {
    return import("./commissions-checkbox-label.tsx");
  };

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [whatsappChannelType, emailChannelType, webHomeChannelType];
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }

  webHomeHeaderImporter: ComponentImporter<WebHomeHeaderParams> = () => {
    return import("./web-home-header.tsx");
  };

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "manual";
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const mediterraneaOnboardingOptions =
  new MediterraneaOnboardingOptions();
