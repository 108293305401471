/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { mgAlfaTenant } from "./code.ts";
import { mgAlfaFullLabel } from "./full-label.ts";
import { mgAlfaLabel } from "./label.ts";
import { mgAlfaOnboardingOptions } from "./mgalfa-onboarding-options.ts";

class MgAlfaTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_mgalfa.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return mgAlfaTenant;
  }

  getPathLabel(): string | null {
    return "mg-alfa";
  }

  getLabel(): string {
    return mgAlfaLabel;
  }

  getFullLabel(): string {
    return mgAlfaFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return mgAlfaOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@mgalfa.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "cuentas@mgalfa.com.ar";
  }

  getCuit(): string {
    return "30-71699605-7";
  }

  getCnvRegistryId(): string | null {
    return "1350";
  }

  getColors(): ColorScheme {
    return {
      primary: "#adb0b3",
      secondary: "#606366",
      tertiary: "#FFFFFF",
    };
  }
}

const mgAlfaTenantHandler = new MgAlfaTenantHandler();

export default mgAlfaTenantHandler;
