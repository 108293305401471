/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentToFulfill } from "../../../credit/autocomplete-files/types.ts";
import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";
import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { argenpymesB2cOptions } from "./argenpymes-b2c-options.ts";
import { argenpymesTenant } from "./code.ts";
import { documentsToFulfill } from "./documents-to-fulfill.ts";
import { argenpymesFullLabel } from "./full-label.ts";
import { argenpymesLabel } from "./label.ts";

class ArgenpymesTenantHandler extends SgrTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_argenpymes.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return argenpymesTenant;
  }

  getPathLabel(): string | null {
    return "argenpymes";
  }

  getLabel(): string {
    return argenpymesLabel;
  }

  getFullLabel(): string {
    return argenpymesFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "33-71492413-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  getDocumentsToFulfill(): DocumentToFulfill[] {
    return documentsToFulfill;
  }

  hasLufe(): boolean {
    return true;
  }

  hasB2cFeature(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  b2cOptions(): B2cOptions | null {
    return argenpymesB2cOptions;
  }

  getColors(): ColorScheme {
    return {
      primary: "#0099CC",
      secondary: "#2D2D2D",
      tertiary: "#F4F6F4",
    };
  }

  prioritizesVerticalOrientantionPhoneView(): boolean {
    return true;
  }

  hasSemaphoreSettings(): boolean {
    return true;
  }
}

const argenpymesTenantHandler = new ArgenpymesTenantHandler();

export default argenpymesTenantHandler;
