/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../../credit/gov-id-number/entity-type.ts";

export const documentsToComplete: DocumentToFulfill[] = [
  {
    label: "Contrato de servicios",
    filename: "contrato_servicios.docx",
    field: "contrato-servicios-docx",
    path: "/lam/contrato_servicios.docx",
    type: DocumentExtension.docx,
    availableData: true,
    entityType: EntityType.Any,
  },
  {
    label: "Perfil inversor PF",
    filename: "perfil_inversor_PF.docx",
    field: "perfil-inversor-pf-docx",
    path: "/lam/perfil_inversor_PF.docx",
    type: DocumentExtension.docx,
    availableData: false,
    entityType: EntityType.OnlyHumans,
  },
  {
    label: "Perfil inversor PJ",
    filename: "perfil_inversor_PJ.docx",
    field: "perfil-inversor-pj-docx",
    path: "/lam/perfil_inversor_PJ.docx",
    type: DocumentExtension.docx,
    availableData: false,
    entityType: EntityType.OnlyCompanies,
  },
  {
    label: "Formulario de apertura PF",
    filename: "form_apertura_PF.xlsx",
    field: "form-apertura-pf-xlsx",
    path: "/lam/form_apertura_PF.xlsx",
    type: DocumentExtension.xlsx,
    availableData: true,
    entityType: EntityType.OnlyHumans,
  },
  {
    label: "Formulario de apertura PJ",
    filename: "form_apertura_PJ.xlsx",
    field: "form-apertura-pj-xlsx",
    path: "/lam/form_apertura_PJ.xlsx",
    type: DocumentExtension.xlsx,
    availableData: true,
    entityType: EntityType.OnlyCompanies,
  },
];
