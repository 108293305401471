/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData, LiveFieldRequired } from "./types.ts";

export const resolveFieldRequired = <TExtraLiveData>(
  isRequired: LiveFieldRequired<TExtraLiveData> | undefined,
  value: any,
  liveData: LiveData<TExtraLiveData>
) =>
  typeof isRequired === "function"
    ? isRequired({ value, liveData })
    : !!isRequired;
