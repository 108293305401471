/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";

class AvaluarB2cOptions implements B2cOptions {
  async getB2cNavbarPageSmallLogo() {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_avaluar.svg?react")).default;
  }

  allowPersonalAccountsAsEndClients(): boolean {
    return false;
  }

  endClientLandingBackgroundPath(): string | null {
    return "/avaluar_background.png";
  }

  endClientHasWhatsappButton(): boolean {
    return false;
  }

  endClientHasRiskPreview(): boolean {
    return false;
  }

  endClientNavbarColor(): string | null {
    return "#F2F5F2";
  }

  endClientContactAdvisor(): boolean {
    return true;
  }

  getAdvisorPhoneNumberForB2CCampaigns(): string | null {
    return "5491124646861";
  }

  useOnlyHostTenantPreApproval(): boolean {
    return false;
  }

  async getPreApprovalGrantedMoneyCalculator() {
    return null;
  }

  getAdvisorEmail(): string | null {
    return null;
  }

  getStartingWhatsappMessage(): string | null {
    return null;
  }
}

export const avaluarB2cOptions = new AvaluarB2cOptions();
