/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { southernTrustTenant } from "./code.ts";
import { southernTrustFullLabel } from "./full-label.ts";
import { southernTrustLabel } from "./label.ts";

class SouthernTrustTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return southernTrustTenant;
  }

  getPathLabel(): string | null {
    return "southern-trust";
  }

  getLabel(): string {
    return southernTrustLabel;
  }

  getFullLabel(): string {
    return southernTrustFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-71060890-9";
  }
}

const southernTrustTenantHandler = new SouthernTrustTenantHandler();

export default southernTrustTenantHandler;
