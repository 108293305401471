/* DON'T EDIT THIS FILE: edit original and run build again */ import fileDownload from "js-file-download";

const getBytesFromBlob = async (blob: Blob, length: number) => {
  const arrayBuffer = await blob.slice(0, length).arrayBuffer();
  return new Uint8Array(arrayBuffer);
};

export const downloadBlob = async (blob: Blob, fileName: string) => {
  await fileDownload(blob, fileName);
  // @ts-expect-error we inject this global variable in the browser
  const notifier = window.$notifyNewFileDownloaded;
  if (notifier) {
    notifier({
      fileName,
      size: blob.size,
      firstBytes: await getBytesFromBlob(blob, 100),
    });
  }
};

export const downloadUrl = async (url: string, fileName: string) => {
  await downloadBlob(await (await fetch(url)).blob(), fileName);
};
