/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import { DocumentToFulfill } from "../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import {
  DocumentHeaderParams,
  EmailHeaderParams,
  OnboardingOptions,
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../framework/tenant-handler-type/onboarding-options.ts";
import {
  ComponentImporter,
  ComponentImporterNoProps,
} from "../../framework/theme/component-importer-type.ts";
import { webHomeChannelType } from "../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../onboarding/signatura-document-validations/document-validations.ts";
import { FunctionComponent } from "react";

export class BaseOnboardingOptions implements OnboardingOptions {
  hasSignatura(): boolean {
    return false;
  }

  hasEsco(): boolean {
    return false;
  }

  hasAune(): boolean {
    return false;
  }

  hasRevenueVolume(): boolean {
    return true;
  }

  hasGenderField(): boolean {
    return true;
  }

  hasVideoSelfie(): boolean {
    return true;
  }

  hasSnailMail(): boolean {
    return true;
  }

  hasFullGeneralInfoSection(): boolean {
    return true;
  }

  hasShareholders(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return true;
  }

  hasOwnedEntityField(): boolean {
    return true;
  }

  finalBeneficiariesPercentage(): number {
    return 10;
  }

  usesUruguayLaw(): boolean {
    return false;
  }

  hasAccounts(): boolean {
    return true;
  }

  getInvestmentProfileMethod(): "manual" | "score" | "none" {
    return "manual";
  }

  hasRelatedEntitiesSection(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  getSupportedChannelTypes(): string[] {
    return [];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este Mail será detallado en Caja de Valores - Inversores (consulta online de " +
      "saldos y movimientos de la cuenta corriente) y para enviar información sobre " +
      "movimientos y/o resúmenes de cuenta (régimen informativo)"
    );
  }

  useSnailMailViaEmail(): boolean {
    return true;
  }

  getSnailMailSignatureAddress(): string | null {
    return null;
  }

  supportsChannelType(channelType: string): boolean {
    return this.getSupportedChannelTypes().includes(channelType);
  }

  supportsWebHomeChannelAccessType(): boolean {
    return false;
  }

  hasWebHomeChannelClarificationType(): boolean {
    return false;
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [];
  }

  emailHeaderImporter: ComponentImporter<EmailHeaderParams> = () => {
    return null;
  };

  webHomeHeaderImporter: ComponentImporter<WebHomeHeaderParams> = () => {
    if (this.supportsChannelType(webHomeChannelType)) {
      throw new Error("onboardingOptionsMissingWebHomeHeader");
    }
    return null;
  };

  hasSwornStatementAboutLegality(): boolean {
    return false;
  }

  hasProofOfFciRulebookSection(): boolean {
    return false;
  }

  hasProofOfFciRulebookContent(): boolean {
    return false;
  }

  proofOfFciRulebookContentImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      if (
        this.hasProofOfFciRulebookSection() ||
        this.hasProofOfFciRulebookContent()
      ) {
        throw new Error("onboardingOptionsMissingProofOfFciRulebook");
      }
      return null;
    };

  proofOfFciRulebookAfterLabelImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      if (this.hasProofOfFciRulebookSection()) {
        throw new Error("onboardingOptionsMissingProofOfFciRulebook");
      }
      return null;
    };

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    if (this.hasProofOfFciRulebookSection()) {
      throw new Error("onboardingOptionsMissingProofOfFciRulebook");
    }
    return {};
  }

  hasEstimatedAmountToInvest(): boolean {
    return false;
  }

  getPepConditionType(): "simple" | "full" {
    return "simple";
  }

  hasFatca(): "simple" | "full" | "none" {
    return "simple";
  }

  hasMatbaRofex(): boolean {
    return true;
  }

  getOpenMatbaRofexAccountDefaultValue(): undefined | "yes" | "no" {
    return undefined;
  }

  hasSgrComissions(): boolean {
    return true;
  }

  getSrgCommissionsDefaultValue(): undefined | "yes" | "no" {
    return undefined;
  }

  getBeneficiaryEntityType(): string {
    return EntityType.Any;
  }

  hasCorporateAdminSection(): boolean {
    return false;
  }

  hasOnboardingCoholdersSection(): boolean {
    return true;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return true;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return true;
  }

  hasMutualGuaranteeContract(): boolean {
    return false;
  }

  hasTerms(): boolean {
    return true;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    if (this.hasTerms()) {
      throw new Error("onboardingOptionsMissingTermsOfUse");
    }
    return null;
  };

  getTermsOfUseTitle(): string {
    if (this.hasTerms()) {
      throw new Error("onboardingOptionsMissingTermsOfUse");
    }
    return "";
  }

  hasSwornStatement(): boolean {
    return false;
  }

  hasBeneficiaryCanBePublic(): boolean {
    return true;
  }

  hasBeneficiaryDoc(): boolean {
    return true;
  }

  hasIsQualifiedInvestorText(): boolean {
    return false;
  }

  allowPersonalAccount(): boolean {
    return true;
  }

  allowForeignCompanies(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return () => null;
  }

  usesFinancialGuarantee(): boolean {
    return false;
  }

  getOpenAccountAgreementLabel(): string {
    return "Convenio de apertura";
  }

  getOnboardingLabel(): string {
    return "Apertura de Cuenta";
  }

  getOnboardingMailLabel(): string {
    return "apertura de cuenta comitente";
  }

  canBackupOnboardings(): boolean {
    return false;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return false;
  }

  hasPositionFieldRequired(): boolean {
    return false;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return true;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "automatic";
  }

  requiresAcceptingTermsAndConditions(): boolean {
    return false;
  }

  termsAndConditionsImporter: ComponentImporterNoProps = () => {
    if (this.requiresAcceptingTermsAndConditions()) {
      throw new Error("onboardingOptionsMissingTermsAndConditionsWidget");
    }
    return null;
  };

  requiresAcceptingCommissions(): boolean {
    return false;
  }

  commissionsImporter: ComponentImporterNoProps = () => {
    if (this.requiresAcceptingCommissions()) {
      throw new Error("onboardingOptionsMissingCommissionsWidget");
    }
    return null;
  };

  hasPymeTypesField(): boolean {
    return false;
  }

  hasOperationsInUsField(): boolean {
    return true;
  }

  getDocumentsToComplete(): DocumentToFulfill[] {
    return [];
  }
}
