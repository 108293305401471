/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCallback, useEffect, useState } from "react";

type UseDelayedChangeTextInputParam<TValue> = {
  value: TValue;
  onEnterPress?: () => void;
  setValue: (value: TValue) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onChange?: (e: React.ChangeEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

type DelayedChangeTextInputState<TValue> = {
  value: TValue;
  onBlur: (e: React.FocusEvent) => void;
  onFocus: (e: React.FocusEvent) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
  setValue: (value: TValue) => void;
};

export const useDelayedChangeTextInputState = <TValue,>({
  value,
  setValue: originalSetValue,
  onEnterPress = undefined,
  onBlur: originalOnBlur = undefined,
  onFocus: originalOnFocus = undefined,
  onKeyDown: originalOnKeyDown = undefined,
}: UseDelayedChangeTextInputParam<TValue>): DelayedChangeTextInputState<TValue> => {
  const [editingValue, setEditingValue] = useState({
    oldValue: value,
    editingValue: value,
  });
  const [valueWhenEnterWasPressed, setValueWhenEnterWasPressed] = useState(
    null as null | TValue
  );
  const [focused, setFocused] = useState<boolean>(false);

  const maybePropagateChange = useCallback(() => {
    if (editingValue.editingValue !== editingValue.oldValue) {
      originalSetValue(editingValue.editingValue);
    }
  }, [editingValue.editingValue, editingValue.oldValue, originalSetValue]);

  // this is complicated because we don't have an easy way to track when was the state change
  //   finally applied, and we shouldn't dispatch the event before the value is already propagated
  useEffect(() => {
    if (
      onEnterPress &&
      valueWhenEnterWasPressed !== null &&
      valueWhenEnterWasPressed === editingValue.oldValue
    ) {
      setValueWhenEnterWasPressed(null);
      onEnterPress();
    }
  }, [onEnterPress, valueWhenEnterWasPressed, editingValue.oldValue]);

  const onBlur = (event: React.FocusEvent) => {
    setFocused(false);
    if (originalOnBlur) {
      originalOnBlur(event);
    }
    maybePropagateChange();
  };

  const setValue = (newValue: TValue) => {
    if (focused) {
      setEditingValue({ oldValue: value, editingValue: newValue });
    } else {
      // only delay user input: this was probably autocompleted by the browser
      originalSetValue(newValue);
    }
  };

  useEffect(() => {
    if (editingValue.oldValue !== value) {
      setEditingValue({ oldValue: value, editingValue: value });
    }
  }, [editingValue.oldValue, value]);

  const onFocus = (event: React.FocusEvent) => {
    if (originalOnFocus) {
      originalOnFocus(event);
    }
    setFocused(true);
  };

  return {
    value: editingValue.editingValue,
    onBlur,
    onFocus,
    setValue,
    onKeyDown: (event: React.KeyboardEvent) => {
      if (event.code === "Enter") {
        maybePropagateChange();
        setValueWhenEnterWasPressed(editingValue.editingValue);
      }
      if (originalOnKeyDown) {
        originalOnKeyDown(event);
      }
    },
  };
};
