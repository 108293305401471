/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { czTenant } from "./code.ts";
import { czOnboardingOptions } from "./cz-onboarding-options.ts";
import { czFullLabel } from "./full-label.ts";
import { czLabel } from "./label.ts";

class CzTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_cz.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return czTenant;
  }

  getPathLabel(): string | null {
    return "czwm";
  }

  getLabel(): string {
    return czLabel;
  }

  getFullLabel(): string {
    return czFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return czOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@czwm.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "info@czwm.com.ar";
  }

  getCuit(): string {
    return "30-71788608-5";
  }

  getCnvRegistryId(): string | null {
    return "2185";
  }

  getColors(): ColorScheme {
    return {
      primary: "#0111a2",
      secondary: "#1a105f",
      tertiary: "#0111a2",
    };
  }
}

const czTenantHandler = new CzTenantHandler();

export default czTenantHandler;
