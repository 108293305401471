/* DON'T EDIT THIS FILE: edit original and run build again */ export const mapArrayToObject = <T, V, TKey extends keyof any>(
  iterable: Iterable<T>,
  callback: (_: T) => [TKey, V]
): Record<TKey, V> => {
  const out = {} as Record<TKey, V>;
  for (const item of iterable) {
    const [newKey, newValue] = callback(item);
    out[newKey] = newValue;
  }
  return out;
};
