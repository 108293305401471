/* DON'T EDIT THIS FILE: edit original and run build again */ import { SimpleDropdown } from "../../credit/ui/form/simple-dropdown.tsx";
import { useAccountCached } from "../../framework/auth-ui/use-account.ts";
import { getAllowedTenants } from "../../framework/auth/allowed-tenants.ts";
import {
  UpdateTenantCall,
  updateTenantCallName,
} from "../../framework/auth/update-tenant-call-signature.ts";
import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { ascSorter } from "../../framework/core/sort-by-many.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import { getRealmUrlFromProfileTenant } from "../../framework/realm/realm-url.ts";
import { getAllTenantLabels } from "../../framework/tenant-collection/tenant.ts";
import { useNavigate } from "react-router-dom";
import { waitPagePath } from "./wait-path.ts";

// TODO: change to new tenant select

const getTenantOptions = (allowedTenants: string[], profileTenant: string) => {
  const tenantOptions = mapArrayToObject(
    // we add the profileTenant to make sure the UI shows the current tenant correctly even if it's not in the allowed list
    // it's probably already there but it shouldn't matter since repeated values are overwritten in the resulting object.
    [...allowedTenants, profileTenant],
    (code) => [code, getAllTenantLabels()[code]]
  );

  const sortedEntries = Object.entries(tenantOptions).sort(
    ascSorter(([, label]) => label)
  );

  return Object.fromEntries(sortedEntries);
};

export const AdminTenantSelect = () => {
  const { roles, profile, privateProfile } = useAccountCached();
  const navigate = useNavigate();
  if (!roles || !profile || !privateProfile) {
    // if the user is not logged in, we don't show the tenant select
    return null;
  }
  const tenantOptions = getTenantOptions(
    getAllowedTenants({ roles, privateProfile }),
    // eslint-disable-next-line no-restricted-properties
    profile.tenant
  );
  if (Object.keys(tenantOptions).length <= 1) {
    return null;
  }
  return (
    <SimpleDropdown
      // eslint-disable-next-line no-restricted-properties
      value={profile.tenant}
      setValue={async (tenant) => {
        navigate(waitPagePath);
        const { loginToken } = await callFirebaseFunction<UpdateTenantCall>(
          updateTenantCallName,
          {
            tenant,
            loginAgain: true,
          }
        );
        window.location.href =
          // eslint-disable-next-line no-restricted-properties
          getRealmUrlFromProfileTenant(profile.tenant, roles) +
          "/relogin/?loginToken=" +
          loginToken +
          "&loginUid=" +
          profile.uid;
      }}
      sizeCode="sm"
      className="bg-gray30 text-white rounded me-2"
      allowEmpty={false}
      style={{ width: "120px", height: "42px" }}
      options={tenantOptions}
    />
  );
};
