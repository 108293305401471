/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveField } from "./live-field.ts";
import { LiveData } from "./types.ts";

export class LiveFieldset<TExtraLiveData> {
  constructor(private liveFields: LiveField<TExtraLiveData>[]) {}

  filterOnlyVisible(
    liveData: LiveData<TExtraLiveData>
  ): LiveFieldset<TExtraLiveData> {
    return this.filter((liveField) => liveField.isVisible(liveData));
  }

  filter(
    condition: (liveField: LiveField<TExtraLiveData>) => boolean
  ): LiveFieldset<TExtraLiveData> {
    const filteredFieldWrappers = this.liveFields.filter((liveField) =>
      condition(liveField)
    );
    return new LiveFieldset(filteredFieldWrappers);
  }

  getLiveFields() {
    return this.liveFields;
  }

  createDefaultRecord() {
    const recordValue = {};
    for (const liveField of this.liveFields) {
      liveField.setDefaultValueToRecord(recordValue);
    }
    return recordValue;
  }
}
