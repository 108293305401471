/* DON'T EDIT THIS FILE: edit original and run build again */ const rulebookValueOptions = {
  zofingenPesosYa: {
    label:
      "01. ZOFINGEN PESOS YA F.C.I. - Aprobado por Res. CNV N° 22.862 fecha 11 de septiembre de 2024",
    pdfUrl: "/zofingen-pesos-ya.pdf",
  },
  zofingenInfraestructura: {
    label:
      "02. ZOFINGEN INFRAESTRUCTURA F.C.I. - Aprobado por Res. CNV N° 22.251 fecha 19 de abril de 2023",
    pdfUrl: "/zofingen-infraestructura.pdf",
  },
  zofingenFactoringPyme: {
    label:
      "03. ZOFINGEN FACTORING PYME F.C.I. - Aprobado por Res. CNV N° 20.357 fecha 24 de julio de 2019",
    pdfUrl: "/zofingen-factoring-pyme.pdf",
  },
  zofingenPesos24: {
    label:
      "04. ZOFINGEN PESOS 24 F.C.I. - Aprobado por Res. CNV N° 20.740 fecha 5 de agosto del 2020",
    pdfUrl: "/zofingen-pesos-24.pdf",
  },
  zofingenFaroFund: {
    label:
      "05. ZOFINGEN FARO FUND F.C.I. - Aprobado por Res. CNV N° 21.826 fecha 15 de junio de 2022 - Última modificación aprobada por Res. CNV N° 23.056 fecha 19 de febrero de 2025",
    pdfUrl: "/zofingen-faro-fund.pdf",
  },
  zofingenTotalReturnDolar: {
    label:
      "06. ZOFINGEN TOTAL RETURN DÓLAR F.C.I. - Aprobado por Res. CNV N° 19.329 fecha 2 de febrero de 2018 - Última modificación aprobada por Res. CNV N° 21.249 fecha 1 de julio de 2021",
    pdfUrl: "/zofingen-total-return-dolar.pdf",
  },
};

export default rulebookValueOptions;
