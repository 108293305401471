// DON'T EDIT THIS FILE: edit the original Triggers.ts files and regenerate derived files

import hook0 from "../credit/common-folder-listing/navbar-collect.front.tsx";
import hook1 from "../credit/control-panel/navbar-collect.front.tsx";
import hook2 from "../credit/lufe-listing/navbar-collect.front.tsx";
import hook3 from "../credit/marketplace-common-listing/navbar-collect.front.tsx";
import hook4 from "../credit/risk-matrix/navbar-collect.front.tsx";
import hook5 from "../credit/sgrs-reports/navbar-collect.front.tsx";
import hook6 from "../framework/chat/navbar-collect.front.tsx";
import hook7 from "../framework/counterparties-pages/navbar-collect.front.tsx";
import hook8 from "../framework/settings-section/navbar-collect.front.tsx";
import hook9 from "../framework/users/navbar-collect.front.tsx";
import hook10 from "../onboarding/onboarding/navbar-collect.front.tsx";
import hook11 from "../orders/order-routing/navbar-collect.front.tsx";

export default [hook0, hook1, hook2, hook3, hook4, hook5, hook6, hook7, hook8, hook9, hook10, hook11];
