/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData } from "../../../framework/live-form/types.ts";
import {
  BaseTextLiveField,
  BaseTextLiveFieldSpec,
} from "../../../framework/live-form/ui/base-text-live-field-spec.tsx";
import { textLiveFieldType } from "./type.ts";

export class TextLiveField<
  TExtraLiveData
> extends BaseTextLiveField<TExtraLiveData> {
  constructor(spec: BaseTextLiveFieldSpec<TExtraLiveData>) {
    super(spec, textLiveFieldType);
  }

  isEmpty(liveData: LiveData<TExtraLiveData>) {
    return !this.getValue(liveData);
  }
}
