/* DON'T EDIT THIS FILE: edit original and run build again */ import { investorUserCompanyTypeId } from "../../framework/user-company-type-const/user-company-type-const.ts";
import {
  commonInternalRoles,
  fciSpecificInternalRoles,
} from "../../framework/internal-roles/internal-roles.ts";
import { Roles } from "../../framework/permission/schema.ts";
import { BaseTenantHandler } from "./base-tenant-handler.ts";

export abstract class InvestorTenantHandler extends BaseTenantHandler {
  getUserCompanyType(): string {
    return investorUserCompanyTypeId;
  }

  getInternalRoles = (): Roles[] => {
    const out: Roles[] = [...commonInternalRoles, ...fciSpecificInternalRoles];
    return out;
  };
}
