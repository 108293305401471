/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAnalyzeLine,
  canSeePreApprovalAnalysisDetail,
  canUsePreApprovalAnalysis,
} from "./permissions.ts";

const linesAnalysisPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canAnalyzeLine,
      description: "Puede analizar carpetas y actualizar información de Nosis",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canUsePreApprovalAnalysis,
      description: "Puede usar el análisis pre-aprobado",
      roles: [
        Roles.admin,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.matchfinAnalyst,
        Roles.salesManager,
      ],
    },
    {
      name: canSeePreApprovalAnalysisDetail,
      description: "Puede ver el detalle del análisis pre-aprobado",
      roles: [Roles.admin, Roles.matchfinAnalyst],
    },
  ],
};

export default linesAnalysisPermissions;
