/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { mapObject } from "../../framework/core/map-object.ts";
import { Roles } from "./schema.ts";
// @ts-expect-error the hook is auto generated
import permissionsCollected from "../../hook/permission-collect.js";
import { PermissionCollection, PermissionItem } from "./types.ts";

let collectedAllPermissions = false;
let allPermissionsCollection: PermissionCollection[] | undefined;

let loadedRolePermissions = false;
const rolePermissionsAsArrays: Record<Roles, string[]> = {} as Record<
  Roles,
  string[]
>;

export const getCollectedPermissions = ():
  | PermissionCollection[]
  | undefined => {
  if (!collectedAllPermissions) {
    allPermissionsCollection = permissionsCollected
      ? [...permissionsCollected]
      : [];
    collectedAllPermissions = true;
  }
  return allPermissionsCollection;
};

const getRolePermissionsAsArrays = (): Record<Roles, string[]> => {
  if (loadedRolePermissions) {
    return rolePermissionsAsArrays;
  } else {
    const allPermissions = getCollectedPermissions();
    allPermissions?.forEach((permissionCollected: PermissionCollection) => {
      permissionCollected.permissions.forEach((permission: PermissionItem) => {
        const { name, roles } = permission;
        roles.forEach((role: Roles) => {
          if (!rolePermissionsAsArrays[role]) {
            rolePermissionsAsArrays[role] = [];
          }
          rolePermissionsAsArrays[role].push(name);
        });
      });
    });
    loadedRolePermissions = true;
    return rolePermissionsAsArrays;
  }
};

export const getRolePermissions = (): Record<Roles, Record<string, true>> =>
  mapObject(getRolePermissionsAsArrays(), ([role, permissionsArray]) => [
    role,
    mapArrayToObject(permissionsArray, (item) => [item, true]),
  ]);
