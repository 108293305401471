/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveFieldRenderer } from "../../framework/live-form/ui/types.ts";
// @ts-expect-error the hook is auto generated
import hookRenderers from "../../hook/live-form-renderer-collect.js";
import {
  liveFieldRenderers,
  registerLiveFieldRenderer,
} from "./type-renderers.ts";

let collected = false;

const collectLiveFormRenderers = () => {
  if (collected) {
    return;
  }
  collected = true;
  for (const renderers of hookRenderers) {
    for (const renderer of renderers) {
      registerLiveFieldRenderer(renderer);
    }
  }
};

export const getLiveFormInputFactory = <TExtraLiveData>(
  fieldType: string
): LiveFieldRenderer<TExtraLiveData> => {
  collectLiveFormRenderers();
  if (!(fieldType in liveFieldRenderers)) {
    throw new Error("unknownFieldType: " + fieldType);
  }
  return liveFieldRenderers[fieldType] as LiveFieldRenderer<TExtraLiveData>;
};
