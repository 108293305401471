/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "./schema.ts";

export const roleLabels: Record<Roles, string> = {
  [Roles.admin]: "Administrador de matchFin",
  [Roles.tenantAdmin]: "Administrador de cuenta",
  [Roles.auneSupport]: "Soporte",
  [Roles.matchfinAnalyst]: "Analista de matchFin",
  [Roles.analyst]: "Analista",
  [Roles.salesManager]: "Gerente comercial",
  [Roles.sales]: "Comercial",
  [Roles.matchfinSales]: "Comercial de matchFin",
  [Roles.complianceOfficer]: "Oficial de cumplimiento",
  [Roles.backOffice]: "Back office",
  [Roles.trader]: "Trader",
  [Roles.portfolioManager]: "Portfolio manager",
  [Roles.canFilterbyAlycInvestor]: "Inversor que además puede filtrar por Alyc",
  [Roles.investor]: "Inversor",
  [Roles.guestSgr]: "SGR",
  [Roles.guestBank]: "Banco",
  [Roles.producer]: "Productor",
  [Roles.headOfProducers]: "Gerente de productores",
  [Roles.endClientUser]: "Usuario de un cliente final",
  [Roles.matchfinEndClientUser]: "Usuario de un cliente final de matchFin",
  [Roles.pendingApproval]: "Pendiente de aprobación",
  [Roles.tenantRealmPendingApproval]:
    "Pendiente de aprobación (dominio separado)",
  [Roles.b2cEndclientUser]: "Usuario de un cliente final",
};

export const getRoleLabel = (role: Roles): string => roleLabels[role];
