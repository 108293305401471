/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  TermsOfUseParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import { FunctionComponent } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class AwaOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }
}

export const awaOnboardingOptions = new AwaOnboardingOptions();
