/* DON'T EDIT THIS FILE: edit original and run build again */ import { useGetSectionNavigationAndSetAsDirty } from "../../credit/section-engine/section-navigation.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { EntitionEditor } from "../../framework/live-form-entition/entition-editor.tsx";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import { LiveRecord } from "../../framework/live-form/live-record.ts";
import { LiveData } from "../../framework/live-form/types.ts";
import { RawLiveFormViewer } from "../../framework/live-form/ui/raw-live-form-viewer.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../framework/live-form/ui/types.ts";
import { EntitionLiveField } from "./live-field.ts";
import { entitionLiveFieldType } from "./type.ts";

const Input: LiveFieldInput<FolderLiveData> = ({
  liveCell,
  getLiveFormInputFactory,
  Widgets,
}) => {
  const { setAsDirty } = useGetSectionNavigationAndSetAsDirty();
  const personInlineEntityId = liveCell.getValue() ?? null;
  const liveField = liveCell.getLiveField() as EntitionLiveField;
  const fieldset = liveField.getFieldset();
  return (
    <EntitionEditor
      getLiveFormInputFactory={getLiveFormInputFactory}
      fieldset={fieldset}
      entityId={personInlineEntityId as string}
      showErrors={liveCell.getShowErrors()}
      liveData={liveCell.getLiveData()}
      Widgets={Widgets}
      onAfterEdit={() => setAsDirty()}
    />
  );
};

const Viewer: LiveFieldViewer<FolderLiveData> = ({
  getLiveFormInputFactory,
  liveCell,
  Widgets,
  showLabels,
}) => {
  const personInlineEntityId = (liveCell.getValue() as string) ?? null;
  const previousLiveData = liveCell.getLiveData();
  const inlineEntityHandler = notNull(previousLiveData.inlineEntityHandler);
  const liveData: LiveData<FolderLiveData> = {
    ...previousLiveData,
    recordValue: personInlineEntityId
      ? inlineEntityHandler.getInlineEntityById(personInlineEntityId) ?? {}
      : {},
  };
  const liveField = liveCell.getLiveField() as EntitionLiveField;
  const fieldset = liveField.getFieldset();
  const liveRecord = new LiveRecord(
    fieldset,
    liveData,
    liveCell.getShowErrors(),
    liveCell.isDisabled()
  );
  return (
    <RawLiveFormViewer
      getLiveFormInputFactory={getLiveFormInputFactory}
      liveRecord={liveRecord}
      Widgets={Widgets}
      showLabels={showLabels}
    />
  );
};

export const entitionLiveFieldRenderers: LiveFieldRenderer<FolderLiveData> = {
  type: entitionLiveFieldType,
  Input,
  Viewer,
};
