/* DON'T EDIT THIS FILE: edit original and run build again */ import { FileBlankOutlineIcon } from "../../framework/theme-icons/icon.ts";
import { useRef } from "react";
import { Button, ButtonProps } from "react-bootstrap";

export const FileInputButton = ({
  onChoose,
  className,
  variant,
  size,
  autoFocus,
  uploadLabel,
  disabled,
  multiple = false,
  accept,
}: {
  onChoose: (files: FileList) => void;
  className: string;
  variant: ButtonProps["variant"];
  size: ButtonProps["size"];
  autoFocus?: boolean;
  uploadLabel: string;
  disabled: boolean;
  multiple?: boolean;
  accept?: string;
}) => {
  const inputRef = useRef(null as HTMLInputElement | null);
  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const fileList = e.target.files;
    if (!fileList) {
      throw new Error("missingFileList");
    }
    onChoose(fileList);
    const current = inputRef.current as HTMLButtonElement;
    current.value = "";
  };
  return (
    <>
      <Button
        className={
          "d-flex align-items-center rounded btn-outline-blue50 " + className
        }
        variant={variant}
        size={size}
        onClick={() => {
          const current = inputRef.current as HTMLButtonElement;
          current.click();
        }}
        disabled={disabled}
        autoFocus={autoFocus}
      >
        <FileBlankOutlineIcon size={18} className="me-2" />
        {uploadLabel}
      </Button>
      <input
        style={{ display: "none" }}
        multiple={multiple}
        type="file"
        ref={inputRef}
        onChange={onInputChange}
        accept={accept}
      />
    </>
  );
};
