/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlertProvider } from "../../framework/alert/alert.tsx";
import { AppStateContext } from "../../framework/app-state/app-state-context.ts";
import { useDoc } from "../../framework/firebase/db.ts";
import { getRealmHostname } from "../../framework/realm/realm-url.ts";
import { getSettingsDoc } from "../../framework/settings/settings.ts";
import {
  getAllTenantCodes,
  getTenantHandler,
} from "../../framework/tenant-collection/tenant.ts";
import { SimplePageNotFoundPage } from "../../framework/theme-pages/page-not-found.front.tsx";
import { applyTenantColors } from "../../framework/theme/custom-colors.ts";
import { Loading } from "../../framework/theme/loading.tsx";
import { parseRealmFromUrl } from "../../framework/url-parsing/parse-realm-from-url.ts";
import { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AccountWrapper } from "./account-wrapper.front.tsx";
import { AppRouting } from "./app-routing.front.tsx";
import {
  ConnectionErrorPage,
  RealmDomainMismatchErrorPage,
  UndefinedRealmErrorPage,
} from "./error-pages.front.tsx";
import { MaintenanceWrapper } from "./maintenance-wrapper.front.tsx";
import { OrientationOverlay } from "./orientation-overlay.tsx";

const useRealmColors = (realm: string) => {
  useMemo(() => {
    const tenantColors = getTenantHandler(realm).getColors();
    applyTenantColors(tenantColors);
  }, [realm]);
};

const App = ({
  realmFromUrl,
  basename,
}: {
  realmFromUrl: string;
  basename: string;
}) => {
  const [loading, settings, error] = useDoc(getSettingsDoc());
  useRealmColors(realmFromUrl);
  if (loading) {
    return <Loading />;
  }
  if (error || !settings) {
    return <ConnectionErrorPage />;
  }
  return (
    <AppStateContext.Provider value={{ settings, realmFromUrl, basename }}>
      <MaintenanceWrapper>
        <AccountWrapper>
          <AlertProvider />
          <AppRouting />
        </AccountWrapper>
      </MaintenanceWrapper>
    </AppStateContext.Provider>
  );
};

const RealmApp = ({
  realmFromUrl,
  basename,
}: {
  realmFromUrl: string | undefined;
  basename: string;
}) => {
  if (realmFromUrl == null) {
    return <UndefinedRealmErrorPage />;
  }
  if (!getAllTenantCodes().includes(realmFromUrl)) {
    return <SimplePageNotFoundPage />;
  }
  if (getRealmHostname(realmFromUrl) !== window.location.hostname) {
    return <RealmDomainMismatchErrorPage realmFromUrl={realmFromUrl} />;
  }
  return <App realmFromUrl={realmFromUrl} basename={basename} />;
};

const AppWrapper = () => {
  const [basename, realmFromUrl] = parseRealmFromUrl(document.location);
  return (
    <Router basename={basename}>
      <OrientationOverlay realmFromUrl={realmFromUrl} />
      <RealmApp realmFromUrl={realmFromUrl} basename={basename} />
    </Router>
  );
};

export default AppWrapper;
