/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveField } from "../../../framework/live-form/live-field.ts";
import { LiveData } from "../../../framework/live-form/types.ts";
import {
  BaseTextLiveField,
  BaseTextLiveFieldSpec,
} from "../../../framework/live-form/ui/base-text-live-field-spec.tsx";
import { dateLiveFieldType } from "./type.ts";

const dateValidator = (value: string) => {
  if (value && !/^[0-9]{4}-/.test(value)) {
    return "fecha inválida";
  }
  return null;
};

export class DateLiveField<
  TExtraLiveData
> extends BaseTextLiveField<TExtraLiveData> {
  constructor(spec: BaseTextLiveFieldSpec<TExtraLiveData>) {
    super(spec, dateLiveFieldType);
  }

  isEmpty(liveData: LiveData<TExtraLiveData>) {
    return !this.getValue(liveData);
  }

  getTypeError(
    liveData: LiveData<TExtraLiveData>,
    _context: LiveField<TExtraLiveData>[]
  ) {
    return dateValidator(this.getValue(liveData) as string);
  }
}
