/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../../credit/gov-id-number/entity-type.ts";

export const documentsToFulfill: DocumentToFulfill[] = [
  {
    label: "Información básica agro",
    filename: "informacion_basica_agro.xlsx",
    field: "informacion-basica-agro-xlsx",
    path: "/sgr-documentation/bindgarantias/informacion_basica_agro.xlsx",
    type: DocumentExtension.xlsx,
    availableData: true,
    entityType: EntityType.Any,
  },
];
