/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  defaultNullableCheckboxNoLabel,
  defaultNullableCheckboxYesLabel,
} from "../../../credit/ui/form/checkbox-labels.ts";
import {
  BaseLiveField,
  LiveFieldSpec,
} from "../../../framework/live-form/field-spec.ts";
import {
  LiveData,
  LiveFormWidgetSpec,
} from "../../../framework/live-form/types.ts";
import { booleanLiveFieldType } from "./type.ts";

type BooleanLiveFieldVariant =
  | "simple"
  | "tristate"
  | "tristate-yes"
  | "tristate-no";

type BooleanLiveFieldSpec<TExtraLiveData> = LiveFieldSpec<TExtraLiveData> & {
  variant?: BooleanLiveFieldVariant;
  yesLabel?: string;
  noLabel?: string;
  CheckboxLabel?: LiveFormWidgetSpec<TExtraLiveData>;
};

export class BooleanLiveField<
  TExtraLiveData
> extends BaseLiveField<TExtraLiveData> {
  private variant: BooleanLiveFieldVariant;
  private yesLabel: string;
  private noLabel: string;
  private CheckboxLabel: LiveFormWidgetSpec<TExtraLiveData> | undefined;

  constructor(spec: BooleanLiveFieldSpec<TExtraLiveData>) {
    super(spec, booleanLiveFieldType);
    this.variant = spec.variant ?? "tristate";
    this.yesLabel = spec.yesLabel ?? defaultNullableCheckboxYesLabel;
    this.noLabel = spec.noLabel ?? defaultNullableCheckboxNoLabel;
    this.CheckboxLabel = spec.CheckboxLabel;
  }

  isEmpty(liveData: LiveData<TExtraLiveData>) {
    const value = this.getValue(liveData);
    return value !== true && value !== false;
  }

  getTypeDefaultValue(): any {
    if (this.getVariant() === "simple") {
      return false;
    } else if (this.getVariant() === "tristate-yes") {
      return true;
    } else if (this.getVariant() === "tristate-no") {
      return false;
    } else {
      return undefined;
    }
  }

  dontUseLabelTag() {
    return true;
  }

  getVariant() {
    return this.variant;
  }

  getYesLabel() {
    return this.yesLabel;
  }

  getNoLabel() {
    return this.noLabel;
  }

  getCheckboxLabel() {
    return this.CheckboxLabel;
  }
}
