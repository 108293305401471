/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentToFulfill } from "../../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../../credit/gov-id-number/entity-type.ts";
import {
  DocumentHeaderParams,
  TermsOfUseParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { FunctionComponent } from "react";
import { documentsToComplete } from "./documents-to-complete.ts";
import { lamTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class LamOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return lamTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  hasGenderField(): boolean {
    return false;
  }

  hasVideoSelfie(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  getEmailAfterLabelText(): string | null {
    return null;
  }

  getInvestmentProfileMethod(): "manual" | "score" | "none" {
    return "score";
  }

  hasProofOfFciRulebookSection(): boolean {
    return false;
  }

  hasFullGeneralInfoSection(): boolean {
    return true;
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasCorporateAdminSection(): boolean {
    return true;
  }

  hasBeneficiaryDoc(): boolean {
    return false;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  usesUruguayLaw(): boolean {
    return true;
  }

  getBeneficiaryEntityType(): string {
    return EntityType.OnlyHumans;
  }

  finalBeneficiariesPercentage(): number {
    return 15;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return false;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return false;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }

  getDocumentsToComplete(): DocumentToFulfill[] {
    return documentsToComplete;
  }
}

export const lamOnboardingOptions = new LamOnboardingOptions();
