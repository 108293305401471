/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  LiveFieldConditionCallback,
  LiveFieldConditionParams,
} from "./types.ts";

export const andConditions =
  <TExtraLiveData>(
    ...conditions: LiveFieldConditionCallback<TExtraLiveData>[]
  ): LiveFieldConditionCallback<TExtraLiveData> =>
  (param: LiveFieldConditionParams<TExtraLiveData>) => {
    for (const condition of conditions) {
      if (condition) {
        const result = condition(param);
        if (!result) {
          return false;
        }
      }
    }
    return true;
  };

export const orConditions =
  <TExtraLiveData>(
    ...conditions: LiveFieldConditionCallback<TExtraLiveData>[]
  ): LiveFieldConditionCallback<TExtraLiveData> =>
  (param: LiveFieldConditionParams<TExtraLiveData>) => {
    for (const condition of conditions) {
      if (condition) {
        const result = condition(param);
        if (result) {
          return true;
        }
      }
    }
    return false;
  };

export const notCondition =
  <TExtraLiveData>(
    condition: LiveFieldConditionCallback<TExtraLiveData>
  ): LiveFieldConditionCallback<TExtraLiveData> =>
  (param: LiveFieldConditionParams<TExtraLiveData>) =>
    !condition(param);
