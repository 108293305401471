/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveCell } from "./cell.ts";
import { LiveFieldset } from "./fieldset.ts";
import { LiveData } from "./types.ts";

export class LiveRecord<TExtraLiveData> {
  private fieldset: LiveFieldset<TExtraLiveData>;

  constructor(
    fieldset: LiveFieldset<TExtraLiveData>,
    private liveData: LiveData<TExtraLiveData>,
    private showErrors = true,
    private formDisabled = false
  ) {
    this.fieldset = fieldset.filterOnlyVisible(liveData);
  }

  getAttachments(labelPrefix?: string, keyPrefix?: string) {
    return this.fieldset
      .getLiveFields()
      .map((liveField) =>
        liveField.getAttachments(this.liveData, labelPrefix, keyPrefix)
      )
      .flat();
  }

  getError() {
    for (const liveField of this.fieldset.getLiveFields()) {
      const error = liveField.getLabeledError(
        this.liveData,
        this.fieldset.getLiveFields()
      );
      if (error) {
        return error;
      }
    }
    return null;
  }

  isEmpty() {
    return this.fieldset
      .getLiveFields()
      .every((liveField) => liveField.isEmpty(this.liveData));
  }

  getFieldset() {
    return this.fieldset;
  }

  getLiveData() {
    return this.liveData;
  }

  createCells(): LiveCell<TExtraLiveData>[] {
    return this.fieldset
      .getLiveFields()
      .map(
        (liveField) =>
          new LiveCell(
            liveField,
            this.liveData,
            this.fieldset.getLiveFields(),
            this.showErrors,
            this.formDisabled
          )
      );
  }
}
