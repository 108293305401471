/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentHeaderParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  reutersChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { FunctionComponent } from "react";

class AptorganOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  hasProofOfFciRulebookSection(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [reutersChannelType, whatsappChannelType, emailChannelType];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      {
        fullName: "JOAQUIN FERNANDEZ",
        address: "joaquinfernandez@example.com",
      },
      { fullName: "CARLOS SUAREZ", address: "carlossuarez@example.com" },
      { fullName: "ANDRES DURBAN", address: "andresdurban@example.com" },
      { fullName: "IGNACIO AIMI", address: "ignacioaimi@example.com" },
    ];
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }
}

export const aptorganOnboardingOptions = new AptorganOnboardingOptions();
