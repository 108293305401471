/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessBaseFolders,
  canAccessFoldersSharedWithInternalAnalysts,
  canAccessFoldersSharedWithMfAnalysts,
  canAccessOtherProducersFolders,
  canAccessOtherUsersFolders,
  canBeAssignedAsAssigneeUid,
  canBeAssignedAsProducerUid,
  canDeleteBaseFolderAssignee,
  canDeleteBaseFolderProducer,
  canEditBaseFolderAssignee,
  canEditBaseFolderProducer,
} from "./permissions.ts";

const baseFolderPermissions: PermissionCollection = {
  namespace: "baseFolder",
  permissions: [
    {
      name: canEditBaseFolderAssignee,
      description: "Puede editar el responsable/comercial de la carpeta",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canEditBaseFolderProducer,
      description: "Puede editar el productor de la carpeta",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canDeleteBaseFolderAssignee,
      description: "Puede eliminar el responsable/comercial de la carpeta",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canDeleteBaseFolderProducer,
      description: "Puede eliminar el productor de la carpeta",
      roles: [Roles.admin, Roles.tenantAdmin],
    },
    {
      name: canAccessBaseFolders,
      description: "Puede acceder al documento de la carpeta en la base",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canAccessOtherUsersFolders,
      description: "Puede acceder a carpetas de otros usuarios",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.portfolioManager,
        Roles.auneSupport,
      ],
    },
    {
      name: canAccessOtherProducersFolders,
      description: "Puede acceder a carpetas que tengan productor asignado",
      roles: [Roles.headOfProducers],
    },
    {
      name: canAccessFoldersSharedWithMfAnalysts,
      description:
        "Puede acceder a carpetas compartidas con analistas de MatchFin",
      roles: [Roles.matchfinAnalyst],
    },
    {
      name: canAccessFoldersSharedWithInternalAnalysts,
      description:
        "Puede acceder a carpetas compartidas con analistas internos",
      roles: [Roles.analyst],
    },
    {
      name: canBeAssignedAsProducerUid,
      description: "Puede ser asignado como productor",
      roles: [Roles.producer],
    },
    {
      name: canBeAssignedAsAssigneeUid,
      description: "Puede ser asignado como responsable",
      roles: [Roles.headOfProducers, Roles.sales, Roles.salesManager],
    },
  ],
};

export default baseFolderPermissions;
