/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  getInlineEntityById,
  getInlineEntityByIdOrNull,
  getInlineEntityError,
  isInlineEntityEmpty,
} from "../../credit/entity/inline-entity.ts";
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import {
  BaseLiveField,
  LiveFieldSpec,
} from "../../framework/live-form/field-spec.ts";
import { LiveField } from "../../framework/live-form/live-field.ts";
import { entityLiveFieldType } from "./type.ts";

type EntityLiveFieldSpec<TExtraLiveData> = LiveFieldSpec<TExtraLiveData> & {
  allowEntityTypes?: EntityType;
  includeName?: boolean;
  includeExtraNameFields?: boolean;
  allowForeignEntities?: boolean;
  allowDomesticEntities?: boolean;
  includeDateOfBirth?: boolean;
  useDni?: boolean;
  includeLufeInfo?: boolean;
};

export class EntityLiveField extends BaseLiveField<FolderLiveData> {
  public readonly allowEntityTypes: EntityType;
  public readonly includeName: boolean;
  public readonly includeExtraNameFields: boolean;
  public readonly allowForeignEntities: boolean;
  public readonly allowDomesticEntities: boolean;
  public readonly includeDateOfBirth: boolean;
  public readonly useDni: boolean;
  public readonly includeLufeInfo: boolean;

  constructor({
    allowEntityTypes = EntityType.Any,
    includeName = true,
    includeExtraNameFields = includeName,
    allowForeignEntities = true,
    allowDomesticEntities = true,
    includeDateOfBirth = false,
    useDni = false,
    includeLufeInfo = false,
    ...spec
  }: EntityLiveFieldSpec<FolderLiveData>) {
    super(spec, entityLiveFieldType);
    this.allowEntityTypes = allowEntityTypes;
    this.includeName = includeName;
    this.includeExtraNameFields = includeExtraNameFields;
    this.allowForeignEntities = allowForeignEntities;
    this.allowDomesticEntities = allowDomesticEntities;
    this.includeDateOfBirth = includeDateOfBirth;
    this.useDni = useDni;
    this.includeLufeInfo = includeLufeInfo;
  }

  getSubfieldsError(
    liveData: FolderLiveData,
    _context: LiveField<FolderLiveData>[]
  ) {
    const value = this.getValue(liveData) as string;
    if (!value) {
      return null;
    }
    const inlineEntity = getInlineEntityById(liveData.baseFolder, value);
    return getInlineEntityError(inlineEntity, {
      allowEntityTypes: this.allowEntityTypes,
    });
  }

  dontUseLabelTag() {
    return true;
  }

  isEmpty(liveData: FolderLiveData) {
    const value = this.getValue(liveData);
    if (!value) {
      return true;
    }
    return isInlineEntityEmpty(
      getInlineEntityByIdOrNull(liveData.baseFolder, value as string)
    );
  }

  getParsedInput(liveData: FolderLiveData) {
    const entityId = this.getValue(liveData) as string;
    if (!entityId) {
      return {};
    }
    const { id: _, ...data } = getInlineEntityById(
      liveData.baseFolder,
      entityId
    );
    return data;
  }
}
