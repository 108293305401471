/* DON'T EDIT THIS FILE: edit original and run build again */ import { isRequiredText } from "../../credit/ui/form/is-required.ts";
import { LabeledAttachment } from "../../framework/attachment-type/attachment-ref-type.ts";
import {
  concatGetAttachmentsKey,
  concatGetAttachmentsLabel,
} from "./get-live-form-attachments.ts";
import { LiveField } from "./live-field.ts";
import { resolveFieldRequired } from "./resolve-field-required.ts";
import {
  LiveData,
  LiveFieldConditionCallback,
  LiveFieldRequired,
  LiveFormRecordValue,
  LiveFormWidgetSpec,
} from "./types.ts";

export type LiveFieldGetErrorParams<TExtraLiveData> = {
  value: any;
  liveField: LiveField<TExtraLiveData>;
  liveData: LiveData<TExtraLiveData>;
  context: LiveField<TExtraLiveData>[];
};

export type LiveFieldGetErrorCallback<TExtraLiveData> = (
  params: LiveFieldGetErrorParams<TExtraLiveData>
) => string | null;

export type LiveFieldSpec<TExtraLiveData> = {
  // not required for labels
  field?: string;
  condition?: LiveFieldConditionCallback<TExtraLiveData>;
  viewMode?: boolean;
  // needed if field is not present
  id?: string;
  label?: string;
  // Sometimes for aesthetics we use "" for label, but this wont work if there's no context like when it's shown outside of the record. For example in indicate the errors where the normal label could be not useful (like ""), or to list the attachments each field has
  standaloneLabel?: string;
  /** a css class name to put to the field */
  className?: string;
  labelClassName?: string;
  required?: LiveFieldRequired<TExtraLiveData>;
  hideOptionalIndicator?: boolean;
  defaultValue?: any;
  // if a string it will be a reference to a widget passed to the Widgets map, if a function it will be rendered as text
  AfterLabel?: LiveFormWidgetSpec<TExtraLiveData>;
  autoFocus?: boolean;
  getError?: LiveFieldGetErrorCallback<TExtraLiveData>;
  disabled?: boolean;
  hideOnRow?: boolean;
};

export abstract class BaseLiveField<TExtraLiveData>
  implements LiveField<TExtraLiveData>
{
  constructor(
    protected fieldSpec: LiveFieldSpec<TExtraLiveData>,
    private type: string
  ) {}

  isRequired(liveData: LiveData<TExtraLiveData>) {
    const fieldSpec = this.fieldSpec;
    return resolveFieldRequired(
      fieldSpec.required,
      this.getValue(liveData),
      liveData
    );
  }

  getParsedInput(liveData: LiveData<TExtraLiveData>): any {
    return { value: this.getValue(liveData) };
  }

  getLabeledError(
    liveData: LiveData<TExtraLiveData>,
    context: LiveField<TExtraLiveData>[]
  ) {
    const error = this.getError(liveData, context);
    const label = this.getErrorLabel();
    if (error) {
      return (label ? label + ": " : "") + error;
    }
    return null;
  }

  /**
   * Errors that are inherent to the type of the field, like a number field with a string value
   */
  getTypeError(
    _liveData: LiveData<TExtraLiveData>,
    _context: LiveField<TExtraLiveData>[]
  ): string | null {
    return null;
  }

  /**
   * For business logic validation that are not inherent to the type of the field
   */
  getCustomError(
    liveData: LiveData<TExtraLiveData>,
    context: LiveField<TExtraLiveData>[]
  ) {
    return (
      this.fieldSpec.getError?.({
        value: this.getValue(liveData),
        liveField: this,
        liveData,
        context,
      }) ?? null
    );
  }

  getThisFieldError(
    liveData: LiveData<TExtraLiveData>,
    context: LiveField<TExtraLiveData>[]
  ): string | null {
    const empty = this.isEmpty(liveData);
    if (empty) {
      if (this.isRequired(liveData)) {
        return isRequiredText;
      }
      return null;
    }
    return (
      this.getTypeError(liveData, context) ??
      this.getCustomError(liveData, context)
    );
  }

  getSubfieldsError(
    _liveData: LiveData<TExtraLiveData>,
    _context: LiveField<TExtraLiveData>[]
  ): string | null {
    return null;
  }

  getError(
    liveData: LiveData<TExtraLiveData>,
    context: LiveField<TExtraLiveData>[]
  ) {
    return (
      this.getThisFieldError(liveData, context) ??
      this.getSubfieldsError(liveData, context)
    );
  }

  abstract isEmpty(liveData: LiveData<TExtraLiveData>): boolean;

  isVisible(liveData: LiveData<TExtraLiveData>) {
    const { condition } = this.fieldSpec;
    return (
      !condition ||
      condition({
        liveData,
        value: this.getValue(liveData),
      })
    );
  }

  getAttachments(
    liveData: LiveData<TExtraLiveData>,
    labelPrefix = "",
    keyPrefix = ""
  ): LabeledAttachment[] {
    return this.getUnprefixedAttachments(
      liveData,
      concatGetAttachmentsLabel(
        labelPrefix,
        this.fieldSpec.standaloneLabel ?? this.fieldSpec.label
      ),
      concatGetAttachmentsKey(
        keyPrefix,
        this.fieldSpec.id ?? this.fieldSpec.field
      )
    );
  }

  getUnprefixedAttachments(
    _liveData: LiveData<TExtraLiveData>,
    _label: string,
    _key: string
  ): LabeledAttachment[] {
    return [];
  }

  getValue(liveData: LiveData<TExtraLiveData>) {
    const field = this.getField();
    return field ? liveData.recordValue?.[field] : undefined;
  }

  getLabel(): string {
    return this.fieldSpec.label ?? "";
  }

  getLabelClassName(): string {
    return this.fieldSpec.labelClassName ?? "";
  }

  getErrorLabel(): string {
    return this.getLabel();
  }

  getAfterLabel(): LiveFormWidgetSpec<TExtraLiveData> | null {
    return this.fieldSpec.AfterLabel ?? null;
  }

  getKey(): string {
    const out = this.fieldSpec.id ?? this.fieldSpec.field;
    if (!out) {
      throw new Error("fieldOrIdMustBePresent");
    }
    return out;
  }

  getType(): string {
    return this.type;
  }

  getAutoFocus(): boolean {
    return !!this.fieldSpec.autoFocus;
  }

  getField(): string | null {
    return this.fieldSpec.field ?? null;
  }

  isDisabled(): boolean {
    return !!this.fieldSpec.disabled;
  }

  hasMarginAfterLabel() {
    return true;
  }

  dontUseLabelTag() {
    return false;
  }

  getSpecDefaultValue(): any {
    return this.fieldSpec.defaultValue;
  }

  hideOptionalIndicator() {
    return !!this.fieldSpec.hideOptionalIndicator;
  }

  isViewMode() {
    return !!this.fieldSpec.viewMode;
  }

  getTypeDefaultValue(): any {
    return undefined;
  }

  getDefaultValue() {
    return this.getSpecDefaultValue() ?? this.getTypeDefaultValue();
  }

  setDefaultValueToRecord(recordValue: LiveFormRecordValue) {
    const field = this.getField();
    if (!field) {
      return;
    }
    const calculatedDefaultValue = this.getDefaultValue();
    if (calculatedDefaultValue !== undefined) {
      recordValue[field] = calculatedDefaultValue;
    }
  }

  showsOwnErrors() {
    return false;
  }

  getClassName() {
    return this.fieldSpec.className ?? "";
  }

  hideOnRow() {
    return !!this.fieldSpec.hideOnRow;
  }

  getRealField(): LiveField<TExtraLiveData> {
    return this;
  }
}
