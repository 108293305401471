/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  LiveData,
  LiveFormWidgetSpec,
} from "../../../framework/live-form/types.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { LiveFormWidgets } from "./types.ts";

export const LiveFormWidgetRenderer = <TExtraLiveData,>({
  Widget,
  Widgets,
  liveData,
}: {
  Widget: LiveFormWidgetSpec<TExtraLiveData> | null;
  Widgets: LiveFormWidgets<TExtraLiveData>;
  liveData: LiveData<TExtraLiveData>;
}) => {
  if (!Widget) {
    return null;
  }
  if (typeof Widget === "string") {
    if (!(Widget in Widgets)) {
      throw new Error("missingAfterLabelWidget: " + Widget);
    }
    const AfterLabelWidget = Widgets[Widget];
    return <AfterLabelWidget {...liveData} />;
  } else if (typeof Widget === "function") {
    // functions that return strings to be used as labels
    return (
      <Div className="w-100 flex-shrink-0 mt-1 mb-3">{Widget(liveData)}</Div>
    );
  } else {
    throw new Error("unknownAfterLabelType: " + typeof Widget);
  }
};
