/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  TermsOfUseParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import { emailChannelType } from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { FunctionComponent } from "react";
import onboardingTermsOfUseTitle from "./onboarding-terms-of-use-title.ts";

class FirstCapitalOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSnailMail(): boolean {
    return false;
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [emailChannelType];
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  useSnailMailViaEmail(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const firstCapitalOnboardingOptions =
  new FirstCapitalOnboardingOptions();
