/* DON'T EDIT THIS FILE: edit original and run build again */ import { LabeledAttachment } from "../../../framework/attachment-type/attachment-ref-type.ts";
import { AttachmentItemMap } from "../../../framework/attachment-ui/attachment-item.ts";
import { getAttachmentOriginalName } from "../../../framework/attachment/attachment-storage.ts";
import { getDottedFileExtension } from "../../../framework/core/get-file-extension.ts";
import { isEmptyObject } from "../../../framework/core/is-empty-object.ts";
import { spaceship } from "../../../framework/core/spaceship.ts";
import { FolderLiveData } from "../../../framework/live-form-folder-live-data/folder-live-data.tsx";
import {
  BaseLiveField,
  LiveFieldSpec,
} from "../../../framework/live-form/field-spec.ts";
import { LiveData } from "../../../framework/live-form/types.ts";
import { multipleFileLiveFieldType } from "./type.ts";

export const getSortedMultipleFileItems = (items: AttachmentItemMap) => {
  const out = Object.values(items);
  out.sort((a: any, b: any) => spaceship(a.time, b.time));
  return out;
};

type MultipleFileLiveFieldSpec = LiveFieldSpec<FolderLiveData> & {
  uploadLabel?: string;
};

export class MultipleFileLiveField extends BaseLiveField<FolderLiveData> {
  private uploadLabel: string;

  constructor(spec: MultipleFileLiveFieldSpec) {
    super(spec, multipleFileLiveFieldType);
    this.uploadLabel = spec.uploadLabel || "Subir documentos";
  }

  isEmpty(liveData: LiveData<FolderLiveData>) {
    const value = this.getValue(liveData) as
      | undefined
      | null
      | Record<string, any>;
    return !value || isEmptyObject(value);
  }

  getUnprefixedAttachments(
    liveData: LiveData<FolderLiveData>,
    label: string,
    key: string
  ): LabeledAttachment[] {
    return getSortedMultipleFileItems(
      (this.getValue(liveData) ?? {}) as AttachmentItemMap
    ).map(({ attachment, id }) => {
      const extension = attachment
        ? getDottedFileExtension(getAttachmentOriginalName(attachment))
        : null;
      return {
        label: label + (extension ? "." + extension : ""),
        key: (key ? key + "." : "") + id,
        attachment,
      };
    });
  }

  getUploadLabel() {
    return this.uploadLabel;
  }
}
