/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentToFulfill } from "../../../credit/autocomplete-files/types.ts";
import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { conavalTenant } from "./code.ts";
import { documentsToFulfill } from "./documents-to-fulfill.ts";
import { conavalFullLabel } from "./full-label.ts";
import { conavalLabel } from "./label.ts";

class ConavalTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return conavalTenant;
  }

  getPathLabel(): string | null {
    return "conaval";
  }

  getLabel(): string {
    return conavalLabel;
  }

  getFullLabel(): string {
    return conavalFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71648038-7";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }

  getDocumentsToFulfill(): DocumentToFulfill[] {
    return documentsToFulfill;
  }
}

const conavalTenantHandler = new ConavalTenantHandler();

export default conavalTenantHandler;
