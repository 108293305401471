/* DON'T EDIT THIS FILE: edit original and run build again */ import { removeAccents } from "../../framework/core/remove-accents.ts";

export enum ArgentineProvinceCode {
  "buenosAires" = "AR-B",
  "catamarca" = "AR-K",
  "chaco" = "AR-H",
  "chubut" = "AR-U",
  "caba" = "AR-C",
  "corrientes" = "AR-W",
  "cordoba" = "AR-X",
  "entreRios" = "AR-E",
  "formosa" = "AR-P",
  "jujuy" = "AR-Y",
  "laPampa" = "AR-L",
  "laRioja" = "AR-F",
  "mendoza" = "AR-M",
  "misiones" = "AR-N",
  "neuquen" = "AR-Q",
  "rioNegro" = "AR-R",
  "salta" = "AR-A",
  "sanJuan" = "AR-J",
  "sanLuis" = "AR-D",
  "santaCruz" = "AR-Z",
  "santaFe" = "AR-S",
  "santiagoDelEstero" = "AR-G",
  "tierraDelFuego" = "AR-V",
  "tucuman" = "AR-T",
}

export enum ArgentineIndecRegionCodes {
  "noroeste" = "noroeste",
  "noreste" = "noreste",
  "centro" = "centro",
  "cuyo" = "cuyo",
  "amba" = "amba",
  "buenosAires" = "buenosAires",
  "patagonia" = "patagonia",
}

export const cabaProvinceCode = ArgentineProvinceCode.caba;
export const baProvinceCode = ArgentineProvinceCode.buenosAires;

export const argentineProvinces: Record<string, string> = {
  [ArgentineProvinceCode.buenosAires]: "Buenos Aires",
  [ArgentineProvinceCode.catamarca]: "Catamarca",
  [ArgentineProvinceCode.chaco]: "Chaco",
  [ArgentineProvinceCode.chubut]: "Chubut",
  [ArgentineProvinceCode.caba]: "Ciudad Autónoma de Buenos Aires",
  [ArgentineProvinceCode.corrientes]: "Corrientes",
  [ArgentineProvinceCode.cordoba]: "Córdoba",
  [ArgentineProvinceCode.entreRios]: "Entre Ríos",
  [ArgentineProvinceCode.formosa]: "Formosa",
  [ArgentineProvinceCode.jujuy]: "Jujuy",
  [ArgentineProvinceCode.laPampa]: "La Pampa",
  [ArgentineProvinceCode.laRioja]: "La Rioja",
  [ArgentineProvinceCode.mendoza]: "Mendoza",
  [ArgentineProvinceCode.misiones]: "Misiones",
  [ArgentineProvinceCode.neuquen]: "Neuquén",
  [ArgentineProvinceCode.rioNegro]: "Río Negro",
  [ArgentineProvinceCode.salta]: "Salta",
  [ArgentineProvinceCode.sanJuan]: "San Juan",
  [ArgentineProvinceCode.sanLuis]: "San Luis",
  [ArgentineProvinceCode.santaCruz]: "Santa Cruz",
  [ArgentineProvinceCode.santaFe]: "Santa Fe",
  [ArgentineProvinceCode.santiagoDelEstero]: "Santiago del Estero",
  [ArgentineProvinceCode.tierraDelFuego]: "Tierra del Fuego",
  [ArgentineProvinceCode.tucuman]: "Tucumán",
};

export const argentineIndecRegions: Record<ArgentineIndecRegionCodes, string> =
  {
    [ArgentineIndecRegionCodes.noroeste]: "Noroeste argentino (NOA)",
    [ArgentineIndecRegionCodes.noreste]: "Noreste argentino (NEA)",
    [ArgentineIndecRegionCodes.centro]: "Centro",
    [ArgentineIndecRegionCodes.cuyo]: "Cuyo",
    [ArgentineIndecRegionCodes.amba]:
      "Área metropolitana de Buenos Aires (AMBA)",
    [ArgentineIndecRegionCodes.buenosAires]: "Buenos Aires",
    [ArgentineIndecRegionCodes.patagonia]: "Patagonia",
  };

export const argentineProvinceToIndecRegionMap: Record<
  ArgentineProvinceCode,
  ArgentineIndecRegionCodes
> = {
  [ArgentineProvinceCode.buenosAires]: ArgentineIndecRegionCodes.buenosAires,
  [ArgentineProvinceCode.catamarca]: ArgentineIndecRegionCodes.noroeste,
  [ArgentineProvinceCode.chaco]: ArgentineIndecRegionCodes.noreste,
  [ArgentineProvinceCode.chubut]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.caba]: ArgentineIndecRegionCodes.amba,
  [ArgentineProvinceCode.corrientes]: ArgentineIndecRegionCodes.noreste,
  [ArgentineProvinceCode.cordoba]: ArgentineIndecRegionCodes.centro,
  [ArgentineProvinceCode.entreRios]: ArgentineIndecRegionCodes.centro,
  [ArgentineProvinceCode.formosa]: ArgentineIndecRegionCodes.noreste,
  [ArgentineProvinceCode.jujuy]: ArgentineIndecRegionCodes.noroeste,
  [ArgentineProvinceCode.laPampa]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.laRioja]: ArgentineIndecRegionCodes.cuyo,
  [ArgentineProvinceCode.mendoza]: ArgentineIndecRegionCodes.cuyo,
  [ArgentineProvinceCode.misiones]: ArgentineIndecRegionCodes.noreste,
  [ArgentineProvinceCode.neuquen]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.rioNegro]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.salta]: ArgentineIndecRegionCodes.noroeste,
  [ArgentineProvinceCode.sanJuan]: ArgentineIndecRegionCodes.cuyo,
  [ArgentineProvinceCode.sanLuis]: ArgentineIndecRegionCodes.cuyo,
  [ArgentineProvinceCode.santaCruz]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.santaFe]: ArgentineIndecRegionCodes.centro,
  [ArgentineProvinceCode.santiagoDelEstero]: ArgentineIndecRegionCodes.noroeste,
  [ArgentineProvinceCode.tierraDelFuego]: ArgentineIndecRegionCodes.patagonia,
  [ArgentineProvinceCode.tucuman]: ArgentineIndecRegionCodes.noroeste,
};

const simplifyName = (name: string) => removeAccents(name.toLowerCase());

const simpleNameToCodeMap = Object.fromEntries(
  Object.entries(argentineProvinces).map(([code, name]) => [
    simplifyName(name),
    code,
  ])
);
simpleNameToCodeMap["capital federal"] = cabaProvinceCode;

export const getArgentinaProvinceCodeByName = (name: string) => {
  const converted = simpleNameToCodeMap[removeAccents(name.toLowerCase())];
  if (converted) {
    return converted;
  } else {
    return null;
  }
};

export const getArgentinaRegionCodeFromProvinceCode = (
  provinceCode: string | undefined
): ArgentineIndecRegionCodes | undefined => {
  if (!provinceCode) {
    return undefined;
  }
  return argentineProvinceToIndecRegionMap[
    provinceCode as ArgentineProvinceCode // FIXME
  ];
};
