/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canEditOpinionReportSection,
  canSetExpirationDate,
  canSubmitOthersContainers,
  canViewFullInfoOpinionReportSection,
  canViewInitialDocumentsSection,
  canViewOpinionReportSection,
  canViewPresentationOpinionReportSection,
} from "./permissions.ts";

const commonSectionsPermissions: PermissionCollection = {
  namespace: "common-sections",
  permissions: [
    {
      name: canViewOpinionReportSection,
      description: "Puede ver la sección dictámenes",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.sales,
        Roles.salesManager,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewInitialDocumentsSection,
      description: "Puede ver la seccion de documentación inicial",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewPresentationOpinionReportSection,
      description: "Puede ver la sección dictámenes en modo presentación",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewFullInfoOpinionReportSection,
      description: "Puede ver toda la información de la sección dictámenes",
      roles: [
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.auneSupport,
      ],
    },
    {
      name: canSetExpirationDate,
      description:
        "Puede establecer una fecha de vencimiento para los documentos cargados",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canEditOpinionReportSection,
      description: "Puede editar la seccion de dictámenes",
      roles: [Roles.admin, Roles.analyst, Roles.matchfinAnalyst],
    },
    {
      name: canSubmitOthersContainers,
      description:
        "Puede revisar y enviar Onboardings y/o Carpetas de otros usuarios",
      roles: [Roles.tenantAdmin, Roles.admin, Roles.auneSupport],
    },
  ],
};

export default commonSectionsPermissions;
