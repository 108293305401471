/* DON'T EDIT THIS FILE: edit original and run build again */ import { getLiveFormInputFactory } from "../../framework/live-form-field-collection/get-input-factory.ts";
import { RawLiveForm } from "../../framework/live-form/ui/raw-live-form.tsx";
import { LiveFormParams } from "../../framework/live-form/ui/types.ts";

export const LiveForm = <TExtraLiveData,>({
  liveRecord,
  saveField,
  Widgets = {},
  autoFocus = false,
  allowDefaultValue = true,
  onEnterPress,
}: LiveFormParams<TExtraLiveData>) => (
  <RawLiveForm<TExtraLiveData>
    getLiveFormInputFactory={getLiveFormInputFactory}
    liveRecord={liveRecord}
    saveField={saveField}
    Widgets={Widgets}
    autoFocus={autoFocus}
    allowDefaultValue={allowDefaultValue}
    onEnterPress={onEnterPress}
  />
);
