/* DON'T EDIT THIS FILE: edit original and run build again */ import { RichTextValue } from "../../credit/rich-text/rich-text-type.ts";
import { notNull } from "../../framework/core/not-null.ts";
import {
  HtmlEditor,
  HtmlViewer,
} from "../../framework/html-editor/html-editor.tsx";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../framework/live-form/ui/types.ts";
import { htmlLiveFieldType } from "./type.ts";

const Viewer: LiveFieldViewer<FolderLiveData> = ({ liveCell }) => {
  const value = liveCell.getValue();
  if (value) {
    return <HtmlViewer value={value} />;
  } else {
    return null;
  }
};

const Input: LiveFieldInput<FolderLiveData> = ({ liveCell, saveField }) => {
  const value = liveCell.getValue() as RichTextValue | null;
  const error = liveCell.getError();
  const liveField = liveCell.getLiveField();
  const field = notNull(liveField.getField());
  return (
    <HtmlEditor
      autoFocus={liveField.getAutoFocus()}
      onChange={(value: RichTextValue, { force }) => {
        if (force) {
          saveField(field, value);
        }
      }}
      editorClassName={error ? "is-invalid" : ""}
      initialValue={value ?? undefined}
      delayed={true}
      userId={notNull(liveCell.getLiveData().specificFolder).ownerUid as string}
    />
  );
};

export const htmlLiveFieldRenderers: LiveFieldRenderer<FolderLiveData> = {
  type: htmlLiveFieldType,
  Input: Input,
  Viewer,
};
