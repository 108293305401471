/* DON'T EDIT THIS FILE: edit original and run build again */ import { booleanLiveFieldRenderers } from "../../framework/live-form-core-types/boolean/renderer.tsx";
import { cbuLiveFieldRenderers } from "../../framework/live-form-core-types/cbu/renderer.tsx";
import { dateLiveFieldRenderers } from "../../framework/live-form-core-types/date/renderer.tsx";
import { emailLiveFieldRenderers } from "../../framework/live-form-core-types/email/renderer.tsx";
import { fileLiveFieldRenderers } from "../../framework/live-form-core-types/file/renderer.tsx";
import { labelLiveFieldRenderers } from "../../framework/live-form-core-types/label/renderer.tsx";
import { listingLiveFieldRenderers } from "../../framework/live-form-core-types/listing/renderer.tsx";
import { locationLiveFieldRenderers } from "../../framework/live-form-core-types/location/renderer.tsx";
import { longTextLiveFieldRenderers } from "../../framework/live-form-core-types/long-text/renderer.tsx";
import { multipleFileLiveFieldRenderers } from "../../framework/live-form-core-types/multiple-file/renderer.tsx";
import { multipleOptionLiveFieldRenderers } from "../../framework/live-form-core-types/multiple-option/renderer.tsx";
import { numberLiveFieldRenderers } from "../../framework/live-form-core-types/number/renderer.tsx";
import { passwordLiveFieldRenderers } from "../../framework/live-form-core-types/password/renderer.tsx";
import { phoneLiveFieldRenderers } from "../../framework/live-form-core-types/phone/renderer.tsx";
import { singleOptionLiveFieldRenderers } from "../../framework/live-form-core-types/single-option/renderer.tsx";
import { textLiveFieldRenderers } from "../../framework/live-form-core-types/text/renderer.tsx";
import { tokenLiveFieldRenderers } from "../../framework/live-form-core-types/token/renderer.tsx";

const coreLiveFieldRenderers = [
  booleanLiveFieldRenderers,
  cbuLiveFieldRenderers,
  dateLiveFieldRenderers,
  emailLiveFieldRenderers,
  fileLiveFieldRenderers,
  labelLiveFieldRenderers,
  listingLiveFieldRenderers,
  locationLiveFieldRenderers,
  longTextLiveFieldRenderers,
  multipleFileLiveFieldRenderers,
  multipleOptionLiveFieldRenderers,
  numberLiveFieldRenderers,
  passwordLiveFieldRenderers,
  phoneLiveFieldRenderers,
  singleOptionLiveFieldRenderers,
  textLiveFieldRenderers,
  tokenLiveFieldRenderers,
];

export default coreLiveFieldRenderers;
