/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseTextLiveField } from "../../../framework/live-form/ui/base-text-live-field-spec.tsx";
import { useLiveImmediateInput } from "../../../framework/live-form/ui/live-immediate-input.tsx";
import { LiveTextViewer } from "../../../framework/live-form/ui/text-viewer.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
} from "../../../framework/live-form/ui/types.ts";
import { PasswordInput } from "./password-input.tsx";
import { passwordLiveFieldType } from "./type.ts";

const ImmediateInput: LiveFieldInput<unknown> = (fieldInfo) => {
  const liveField = fieldInfo.liveCell.getLiveField();
  if (!(liveField instanceof BaseTextLiveField)) {
    throw new Error("passwordLiveFieldError: expected BaseTextLiveField");
  }
  const {
    value = "",
    setValue,
    autoFocus,
    className,
    disabled,
  } = useLiveImmediateInput(fieldInfo);
  return (
    <PasswordInput
      value={value}
      setValue={setValue}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      autoComplete={liveField.getAutoComplete()}
      placeholder={liveField.getPlaceholder()}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.code === "Enter") {
          if (fieldInfo.onEnterPress) {
            fieldInfo.onEnterPress();
          }
        }
      }}
    />
  );
};

export const passwordLiveFieldRenderers: LiveFieldRenderer<unknown> = {
  type: passwordLiveFieldType,
  Input: ImmediateInput,
  Viewer: LiveTextViewer,
};
