/* DON'T EDIT THIS FILE: edit original and run build again */ import React, { CSSProperties } from "react";
import { useIsInPdf } from "./context.ts";

// These wrappers do two things:

//  reduce bundle size by enabling to only loading the pdf dependencies when needed
//  enable the same code to render html and pdf

export type PdfWrapperTagProps = {
  id?: string;
  children?: React.ReactNode;
  minPresenceAhead?: number;
  break?: boolean;
  wrap?: boolean;
  className?: string;
  title?: string;
  style?: CSSProperties;
  href?: string;
  target?: string;
  rel?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  fixed?: boolean;
};

type PdfComponent = React.ComponentType<PdfWrapperTagProps>;

let PdfDiv: PdfComponent;
let PdfSpan: PdfComponent;
let PdfAnchor: PdfComponent;

export const setPdfDiv = (value: PdfComponent) => (PdfDiv = value);

export const setPdfSpan = (value: PdfComponent) => (PdfSpan = value);

export const setPdfAnchor = (value: PdfComponent) => (PdfAnchor = value);

export const Div = ({
  children,
  break: break_,
  minPresenceAhead,
  wrap,
  title,
  onClick,
  ...props
}: PdfWrapperTagProps) => {
  const isInPdf = useIsInPdf();
  if (isInPdf) {
    return (
      <PdfDiv
        children={children}
        minPresenceAhead={minPresenceAhead}
        break={break_}
        wrap={wrap}
        {...props}
      />
    );
  } else {
    return (
      <div title={title} onClick={onClick} {...props}>
        {children}
      </div>
    );
  }
};

export const Span = ({ ...props }: PdfWrapperTagProps) => {
  const isInPdf = useIsInPdf();
  return isInPdf ? <PdfSpan {...props} /> : <span {...props} />;
};

export const Paragraph = ({ className = "", ...props }: PdfWrapperTagProps) => {
  return <Div className={"mb-2 " + className} {...props} />;
};

export const Anchor = ({ href, target, rel, ...props }: PdfWrapperTagProps) => {
  const isInPdf = useIsInPdf();
  return isInPdf ? (
    <PdfAnchor href={href} {...props} />
  ) : (
    <a href={href} target={target} rel={rel} {...props} />
  );
};
