/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { vinverTenant } from "./code.ts";
import { vinverFullLabel } from "./full-label.ts";
import { vinverLabel } from "./label.ts";
import { vinverOnboardingOptions } from "./vinver-onboarding-options.ts";

class VinverTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_vinver.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return vinverTenant;
  }

  getPathLabel(): string | null {
    return "vinver";
  }

  getLabel(): string {
    return vinverLabel;
  }

  getFullLabel(): string {
    return vinverFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return vinverOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@vinver.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "info@vinver.com.ar";
  }

  getCuit(): string {
    return "30-71055225-4";
  }

  getCnvRegistryId(): string | null {
    return "348";
  }

  getColors(): ColorScheme {
    return {
      primary: "#2f9340",
      secondary: "#235629",
      tertiary: "#2f9340",
    };
  }
}

const vinverTenantHandler = new VinverTenantHandler();

export default vinverTenantHandler;
