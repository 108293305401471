/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";

class ArgenpymesB2cOptions implements B2cOptions {
  async getB2cNavbarPageSmallLogo() {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_argenpymes.svg?react")).default;
  }

  allowPersonalAccountsAsEndClients(): boolean {
    return true;
  }

  endClientLandingBackgroundPath(): string | null {
    return "/argenpymes_background.jpg";
  }

  endClientHasWhatsappButton(): boolean {
    return false;
  }

  endClientHasRiskPreview(): boolean {
    return false;
  }

  endClientNavbarColor(): string | null {
    return "#F2F5F2";
  }

  endClientContactAdvisor(): boolean {
    return true;
  }

  getAdvisorPhoneNumberForB2CCampaigns(): string | null {
    return "5491123557377";
  }

  useOnlyHostTenantPreApproval(): boolean {
    return true;
  }

  async getPreApprovalGrantedMoneyCalculator() {
    return (await import("./pre-approval-granted-line-calculator.ts")).default;
  }

  getAdvisorEmail(): string | null {
    return "agustina.marra@allaria.com.ar";
  }

  getStartingWhatsappMessage(): string | null {
    return "Hola%21%20Te%20escribo%20desde%20Expoagro%20para%20consultarte%20sobre%20mi%20linea%20de%20cr%C3%A9dito.";
  }
}

export const argenpymesB2cOptions = new ArgenpymesB2cOptions();
