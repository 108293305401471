/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { puertosTenant } from "./code.ts";
import { puertosFullLabel } from "./full-label.ts";
import { puertosLabel } from "./label.ts";
import { puertosOnboardingOptions } from "./puertos-onboarding-options.ts";

class PuertosTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_puertos.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return puertosTenant;
  }

  getPathLabel(): string | null {
    return "puertos";
  }

  getLabel(): string {
    return puertosLabel;
  }

  getFullLabel(): string {
    return puertosFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return puertosOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@puertossrl.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "jpgalleano@puertossrl.com.ar";
  }

  getCuit(): string {
    return "30-70835722-3";
  }

  getCnvRegistryId(): string | null {
    return "335";
  }

  getColors(): ColorScheme {
    return {
      primary: "#adb0b3",
      secondary: "#606366",
      tertiary: "#FFFFFF",
    };
  }
}

const puertosTenantHandler = new PuertosTenantHandler();

export default puertosTenantHandler;
