/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../../credit/gov-id-number/entity-type.ts";

export const documentsToFulfill: DocumentToFulfill[] = [
  {
    label: "Agro",
    filename: "agro.xlsx",
    field: "agro-xlsx",
    path: "/sgr-documentation/conaval/agro.xlsx",
    type: DocumentExtension.xlsx,
    availableData: false,
    entityType: EntityType.Any,
  },
  {
    label: "Detalle inmb maq rod",
    filename: "detalle_inmb_maq_rod.xlsx",
    field: "detalle-inmb-maq-rod-xlsx",
    path: "/sgr-documentation/conaval/detalle_inmb_maq_rod.xlsx",
    type: DocumentExtension.xlsx,
    availableData: false,
    entityType: EntityType.Any,
  },
  {
    label: "Deudas",
    filename: "deudas.xlsx",
    field: "deudas-xlsx",
    path: "/sgr-documentation/conaval/deudas.xlsx",
    type: DocumentExtension.xlsx,
    availableData: true,
    entityType: EntityType.Any,
  },
  {
    label: "Información básica",
    filename: "informacion_basica.xlsx",
    field: "informacion-basica-xlsx",
    path: "/sgr-documentation/conaval/informacion_basica.xlsx",
    type: DocumentExtension.xlsx,
    availableData: true,
    entityType: EntityType.Any,
  },
];
