/* DON'T EDIT THIS FILE: edit original and run build again */ import { useIsMounted } from "../../framework/react/use-is-mounted.ts";
import { blue50 } from "../../framework/theme/custom-colors.ts";
import { useEffect, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";

const AnimatedLineSpinner = () => {
  const [percent, setPercent] = useState(0);
  const [startTime] = useState(() => new Date());
  const percentPerMs = 0.05;
  useEffect(() => {
    const request = requestAnimationFrame(() =>
      setPercent(
        ((new Date().getTime() - startTime.getTime()) * percentPerMs) % 100
      )
    );
    return () => {
      cancelAnimationFrame(request);
    };
  });
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        left: Math.max(0, percent - 30) + "%",
        right: 100 - percent + "%",
        height: "2px",
        backgroundColor: blue50,
      }}
    />
  );
};

type ExtraButtonProps = Omit<ButtonProps, "onClick" | "disabled">;

type ClickLoadingCallback = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => Promise<unknown>;

export const ManualLoadingButton = ({
  disabled = false,
  onClick,
  loading,
  children,
  ...props
}: {
  loading: boolean;
  disabled?: boolean;
  onClick: ClickLoadingCallback;
} & ExtraButtonProps) => {
  return (
    <Button
      type="button"
      disabled={loading || disabled}
      onClick={onClick}
      style={{
        position: "relative",
        overflow: "hidden",
      }}
      variant={"blue50"}
      {...props}
    >
      {loading ? <AnimatedLineSpinner /> : null}
      {children}
    </Button>
  );
};

export const useLoadingCallback = (
  callback: ClickLoadingCallback
): [boolean, ClickLoadingCallback] => {
  const isMounted = useIsMounted();
  const [saving, setSaving] = useState(false);
  return [
    saving,
    async (event) => {
      setSaving(true);
      try {
        await callback(event);
      } finally {
        if (isMounted()) {
          setSaving(false);
        }
      }
    },
  ];
};

export const LoadingButton = ({
  onClick,
  disabled,
  ...props
}: {
  disabled?: boolean;
  onClick: ClickLoadingCallback;
} & ExtraButtonProps) => {
  const [loading, save] = useLoadingCallback(onClick);
  return (
    <ManualLoadingButton
      onClick={save}
      loading={loading}
      disabled={disabled}
      {...props}
    />
  );
};
