/* DON'T EDIT THIS FILE: edit original and run build again */ import { insertInString } from "../../../framework/core/insert-in-string.ts";
import { range } from "../../../framework/core/range.ts";
import { removeCharAtPosition } from "../../../framework/core/remove-char-at-position.ts";
import { useCallback, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";

type FormControlElement = HTMLTextAreaElement | HTMLInputElement;

export const TokenInput = ({
  autoFocus,
  value,
  setValue,
  disabled,
  className,
  length,
  onKeyDown,
}: {
  autoFocus?: boolean;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  className?: string;
  length: number;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}): React.ReactNode => {
  const arange = range(0, length);
  const refs = useRef<(FormControlElement | null)[]>(arange.map(() => null));
  const onRef = useCallback(
    (node: HTMLInputElement, idx: number) => {
      if (node) {
        refs.current[idx] = node;
        node.style.setProperty("padding-left", "10px", "important");
        node.style.setProperty("padding-right", "10px", "important");
        // @ts-expect-error for tests
        node.setInputValueForTests = (value: string) => {
          if (!value) {
            setValue("");
          } else {
            setValue(value);
          }
          return null;
        };
      }
    },
    [setValue]
  );
  const handleFocus = (e: React.FocusEvent<FormControlElement, Element>) =>
    e.currentTarget.select();
  const handleInput = (input: FormControlElement, idx: number) => {
    if (input.value.length > 1) {
      input.value = input.value[1];
    }
    const inputValue = input.value;
    const lastIdx = value.length;
    if (idx > lastIdx) {
      const lastInput = refs.current[lastIdx];
      if (lastInput) {
        input.value = "";
        lastInput.value = inputValue;
        input = lastInput;
        idx = lastIdx;
      } else {
        return;
      }
    }
    const next = idx + 1 < refs.current.length ? refs.current[idx + 1] : null;
    setValue(insertInString(value, idx, inputValue));
    if (next) {
      next.select();
    }
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<FormControlElement>,
    idx: number
  ) => {
    const input = e.currentTarget;
    const prev = idx - 1 >= 0 ? refs.current[idx - 1] : null;
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      if (input.value !== "") {
        input.value = "";
        setValue(removeCharAtPosition(value, idx));
      }
      if (prev) {
        prev.select();
      }
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };
  const handlePaste = (e: React.ClipboardEvent<FormControlElement>) => {
    const pastedCode = e.clipboardData.getData("text").slice(0, length);
    e.preventDefault();
    setValue(pastedCode);
    const nextIdx = Math.min(pastedCode.length, refs.current.length - 1);
    const next = refs.current[nextIdx];
    if (next) {
      next.select();
    }
  };
  return (
    <InputGroup className={"flex-nowrap " + className}>
      {arange.map((i) => (
        <Form.Control
          key={i}
          className={(i < length - 1 ? "me-1 " : "") + "text-center "}
          type="text"
          maxLength={2}
          value={i < value.length ? value[i] : ""}
          ref={(node: HTMLInputElement) => onRef(node, i)}
          autoFocus={i === 0 && autoFocus}
          disabled={disabled}
          onFocus={handleFocus}
          onChange={(e) => handleInput(e.currentTarget, i)}
          onKeyDown={(e) => handleKeyDown(e, i)}
          onPaste={handlePaste}
        />
      ))}
    </InputGroup>
  );
};
