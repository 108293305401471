/* DON'T EDIT THIS FILE: edit original and run build again */ const fullDirectPositive = "fullDirectPositive";
const fullIndirectPositive = "fullIndirectPositive";
const fullNegative = "fullNegative";
const directPositive = "directPositive";
const indirectPositive = "indirectPositive";
const lowWithinTwoYears = "lowWithinTwoYears";
const lowAfterTwoYears = "lowAfterTwoYears";

export enum FullPepOptions {
  DirectPositive = fullDirectPositive,
  IndirectPositive = fullIndirectPositive,
  Negative = fullNegative,
}
export enum SimplePepOptions {
  DirectPositive = directPositive,
  IndirectPositive = indirectPositive,
  LowWithinTwoYears = lowWithinTwoYears,
  LowAfterTwoYears = lowAfterTwoYears,
}
