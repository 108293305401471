/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { SgrTenantHandler as SgrCompanyTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { GenericIconProps } from "../../../framework/theme-icons/generic-icon.tsx";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { sgrTenant } from "./code.ts";
import { sgrFullLabel } from "./full-label.ts";
import { sgrB2cOptions } from "./sgr-b2c-options.ts";
import { sgrOnboardingOptions } from "./sgr-onboarding-options.ts";
class SgrTenantHandler extends SgrCompanyTenantHandler {
  // Is this a real tenant?
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    return (await import("./auth-page-logo.tsx")).default;
  }

  async getSidebarPageSmallLogo(): Promise<ComponentType<GenericIconProps>> {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return sgrOnboardingOptions;
  }

  getCode(): string {
    return sgrTenant;
  }

  getPathLabel(): string | null {
    return "sgr";
  }

  getLabel(): string {
    return "SGR";
  }

  getFullLabel(): string {
    return sgrFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getComplianceEmailAddress(): string {
    return "compliance@example.com";
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-11111110-1";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
  hasB2cFeature(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  b2cOptions(): B2cOptions | null {
    return sgrB2cOptions;
  }

  getColors(): ColorScheme {
    return {
      primary: "#1AA85B",
      secondary: "#495867",
      tertiary: "#F4F6F4",
    };
  }
}

const sgrTenantHandler = new SgrTenantHandler();

export default sgrTenantHandler;
