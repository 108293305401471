/* DON'T EDIT THIS FILE: edit original and run build again */ import { BankTenantHandler } from "../../../framework/tenant-handlers/bank-tenant-handler.ts";
import { bancodevaloresTenant } from "./code.ts";
import { bancodevaloresFullLabel } from "./full-label.ts";
import { bancodevaloresLabel } from "./label.ts";

class BancoDeValoresTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancodevaloresTenant;
  }

  getPathLabel(): string | null {
    return "banco-de-valores";
  }

  getLabel(): string {
    return bancodevaloresLabel;
  }

  getFullLabel(): string {
    return bancodevaloresFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-57612427-5";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  supportsRiskMatrix(): boolean {
    return false;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }

  getCnvRegistryId(): string {
    return "60";
  }
}

const bancodevaloresTenantHandler = new BancoDeValoresTenantHandler();

export default bancodevaloresTenantHandler;
