/* DON'T EDIT THIS FILE: edit original and run build again */ import { Labeled } from "../../../credit/ui/form/labeled.tsx";
import { LiveCell } from "../../../framework/live-form/cell.ts";
import { LiveRecord } from "../../../framework/live-form/live-record.ts";
import React from "react";
import { LiveFormWidgetRenderer } from "./live-form-widget-renderer.tsx";
import { LiveFieldGetLiveFormInputFactory, LiveFormWidgets } from "./types.ts";

const createLiveFormViewer = <TExtraLiveData,>(
  liveCell: LiveCell<TExtraLiveData>,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory<TExtraLiveData>,
  Widgets: LiveFormWidgets<TExtraLiveData>,
  showLabels: boolean
) => {
  const type = liveCell.getLiveField().getType();
  const inputFactory = getLiveFormInputFactory(type);
  if (!inputFactory) {
    throw new Error("cannotFindInputFactorFor: " + type);
  }
  if (!inputFactory.Viewer) {
    throw new Error("cannotFindInputViewerFor: " + type);
  }
  const Viewer = inputFactory.Viewer;
  return (
    <Viewer
      {...{
        liveCell,
        getLiveFormInputFactory,
        Widgets,
        showLabels,
      }}
    />
  );
};

export const createLiveFormFieldViewer = <TExtraLiveData,>(
  liveCell: LiveCell<TExtraLiveData>,
  Widgets: LiveFormWidgets<TExtraLiveData>,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory<TExtraLiveData>,
  showLabels = true
) => {
  const viewer = createLiveFormViewer(
    liveCell,
    getLiveFormInputFactory,
    Widgets,
    showLabels
  );
  const liveField = liveCell.getLiveField();
  const AfterLabel = liveField.getAfterLabel();
  const label = liveField.getLabel();
  const key = liveField.getKey();
  return (
    <React.Fragment key={key}>
      {showLabels && label ? (
        <Labeled
          dontUseLabelTag={true}
          label={label}
          afterLabel={
            <LiveFormWidgetRenderer
              Widget={AfterLabel}
              Widgets={Widgets}
              liveData={liveCell.getLiveData()}
            />
          }
          input={viewer}
        />
      ) : (
        viewer
      )}
    </React.Fragment>
  );
};

const createFieldViewers = <TExtraLiveData,>(
  liveRecord: LiveRecord<TExtraLiveData>,
  Widgets: LiveFormWidgets<TExtraLiveData>,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory<TExtraLiveData>,
  showLabels: boolean
) =>
  liveRecord
    .createCells()
    .map((liveCell) =>
      createLiveFormFieldViewer(
        liveCell,
        Widgets,
        getLiveFormInputFactory,
        showLabels
      )
    );

// warning: the live record should be created with viewOnly = true in the live data
export const RawLiveFormViewer = <TExtraLiveData,>({
  liveRecord,
  Widgets = {},
  getLiveFormInputFactory,
  showLabels = true,
}: {
  liveRecord: LiveRecord<TExtraLiveData>;
  Widgets?: LiveFormWidgets<TExtraLiveData>;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory<TExtraLiveData>;
  showLabels?: boolean;
}) =>
  createFieldViewers(liveRecord, Widgets, getLiveFormInputFactory, showLabels);
