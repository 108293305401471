/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  BaseLiveField,
  LiveFieldSpec,
} from "../../../framework/live-form/field-spec.ts";

export type BaseTextLiveFieldSpec<TExtraLiveData> =
  LiveFieldSpec<TExtraLiveData> & {
    suffix?: string;
    autoComplete?: string;
    placeholder?: string;
    field: string; // not undefined
  };

export abstract class BaseTextLiveField<
  TExtraLiveData
> extends BaseLiveField<TExtraLiveData> {
  private suffix: string;
  private autoComplete: string;
  private placeholder: string;
  private field: string;

  constructor(spec: BaseTextLiveFieldSpec<TExtraLiveData>, type: string) {
    super(spec, type);
    this.suffix = spec.suffix ?? "";
    this.autoComplete = spec.autoComplete ?? "";
    this.placeholder = spec.placeholder ?? "";
    this.field = spec.field;
  }

  getSuffix() {
    return this.suffix;
  }

  getAutoComplete() {
    return this.autoComplete;
  }

  getPlaceholder(): string {
    return this.placeholder;
  }

  getErrorLabel(): string {
    const label = this.getLabel() || this.getPlaceholder();
    return label;
  }

  getField(): string {
    return this.field;
  }
}
