/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessRolesDescription,
  canDebugPermissions,
  canListUrls,
} from "./permissions.ts";

const pagesPermissions: PermissionCollection = {
  namespace: "pages",
  permissions: [
    {
      name: canListUrls,
      description:
        "Puede acceder a la página con el listado de links disponibles(solo usuarios internos de mF)",
      roles: [Roles.admin],
    },
    {
      name: canDebugPermissions,
      description:
        "Puede acceder a la página de permisos(solo usuarios internos de mF)",
      roles: [Roles.admin],
    },
    {
      name: canAccessRolesDescription,
      description: "Puede acceder a la página de descripción de roles.",
      roles: [Roles.admin, Roles.tenantAdmin],
    },
  ],
};

export default pagesPermissions;
