/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { alycburOnboardingOptions } from "./alycbur-onboarding-options.ts";
import { alycburTenant } from "./code.ts";
import { alycburFullLabel } from "./full-label.ts";
import { alycburLabel } from "./label.ts";

class AlycburTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_alycbur.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return alycburTenant;
  }

  getPathLabel(): string | null {
    return "alycbur";
  }

  getLabel(): string {
    return alycburLabel;
  }

  getFullLabel(): string {
    return alycburFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return alycburOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "legajos@alycbur.com";
  }

  getCuit(): string {
    return "30-71436632-3";
  }

  getCnvRegistryId(): string | null {
    return "207";
  }

  getColors(): ColorScheme {
    return {
      primary: "#FFFFFF",
      secondary: "#919191",
      tertiary: "#FFFFFF",
    };
  }
}

const alycburTenantHandler = new AlycburTenantHandler();

export default alycburTenantHandler;
