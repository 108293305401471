/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  BackgroundCheckResponse,
  entityFoundResponse,
} from "../../credit/background-check/background-check-types.ts";
import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { findInlineEntityByRecord } from "../../credit/entity/inline-entity.ts";
import { Gender } from "../../credit/gender/genders.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import {
  GetEntityInfoCall,
  getEntityInfoCallName,
} from "./get-entity-info-call-signature.ts";

export const getEntityInfo = async (
  tenant: string,
  {
    baseFolder,
    country,
    foreignIdNumber,
    cuit,
    dni,
    gender,
  }: {
    baseFolder?: BaseFolder;
    country?: string;
    foreignIdNumber?: string;
    cuit?: string;
    dni?: string;
    gender?: Gender;
  },
  includeLufeInfo?: boolean
): Promise<BackgroundCheckResponse> => {
  if (!cuit && !dni && (!country || !foreignIdNumber)) {
    throw new Error("missingEntityInfoParms");
  }
  if (baseFolder) {
    const inlineEntity = findInlineEntityByRecord(baseFolder, {
      cuit,
      dni,
      country,
      foreignIdNumber,
    });
    if (inlineEntity) {
      return {
        type: entityFoundResponse,
        data: inlineEntity,
      };
    }
  }
  return await callFirebaseFunction<GetEntityInfoCall>(getEntityInfoCallName, {
    tenant,
    country: country ?? undefined,
    foreignIdNumber: foreignIdNumber ?? undefined,
    cuit: cuit ?? undefined,
    dni: dni ?? undefined,
    gender: gender ?? undefined,
    includeLufeInfo,
  });
};
