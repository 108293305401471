/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessLineDataLoadPages,
  canAddCommentForAnySource,
  canAddConceptGuarantor,
  canCopyLinesToOtherTenants,
  canCreateLinesInSurveyingStatus,
  canDeleteLineComments,
  canEditSubmittedLines,
  canListNonSmeLines,
  canOpenMultipleLines,
  canRequestMatchfinAnalystsReview,
  canTransitionToAnalysisStatus,
  canUseLineFilters,
} from "./permissions.ts";

const linesPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canOpenMultipleLines,
      description: "Puede crear múltiples nuevas carpetas",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canUseLineFilters,
      description: "Puede usar los filtros de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canListNonSmeLines,
      description: "Puede listar carpetas de PYMES",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.investor,
        Roles.guestBank,
      ],
    },
    {
      name: canAddConceptGuarantor,
      description: "Puede agregar conceptos a un fiador",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canEditSubmittedLines,
      description: "Puede editar carpetas enviadas",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canCreateLinesInSurveyingStatus,
      description: "Puede crear carpetas en estado Carga en proceso(interna)",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },

    {
      name: canCopyLinesToOtherTenants,
      description: "Puede copiar carpetas a otros tenants",
      roles: [Roles.admin],
    },
    {
      name: canTransitionToAnalysisStatus,
      description: "Puede modificar el estado a Análisis",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canRequestMatchfinAnalystsReview,
      description: "Puede solicitar el análisis de un analista de MatchFin",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.analyst,
      ],
    },
    {
      name: canAccessLineDataLoadPages,
      description:
        "Puede acceder al formulario para la carga de información de carpetas matchfinB2C ",
      roles: [Roles.matchfinEndClientUser, Roles.b2cEndclientUser],
    },
    {
      name: canDeleteLineComments,
      description: "Puede eliminar comentarios de las carpetas de crédito.",
      roles: [
        Roles.sales,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.producer,
      ],
    },
    {
      name: canAddCommentForAnySource,
      description: "Puede agregar comentarios para cualquier fuente",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
  ],
};

export default linesPermissions;
