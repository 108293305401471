/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { getConfig } from "../../framework/config/config.ts";
import { CallSignature } from "./call-signature.ts";

const getFunctionsObject = () => {
  const config = getConfig();
  if (config.functionsRegion) {
    // @ts-expect-error typescript is wrong here
    return firebase.app().functions(config.functionsRegion);
  } else {
    // @ts-expect-error typescript is wrong here
    return firebase.functions();
  }
};

export const callFirebaseFunction = async <
  TCallSignature extends CallSignature<any> = never
>(
  name: TCallSignature["name"],
  params: Parameters<TCallSignature["signature"]>[0] = {}
): Promise<Awaited<ReturnType<TCallSignature["signature"]>>> => {
  try {
    const firebaseResponse = await getFunctionsObject().httpsCallable(
      "callFunctionCall",
      { timeout: 1200000 }
    )({
      name,
      params,
    });
    const [error, response] = firebaseResponse.data;
    if (error) {
      throw new Error("errorCallingFirebase: " + name + ", " + response);
    }
    return response;
  } catch (e) {
    throw new Error(
      "catchedFirebaseError: " + name + ", original error: " + e.message
    );
  }
};
