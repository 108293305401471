/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { bolsaDeComercioDelChacoMavAgentId } from "./bdcc-mav-agent-id.ts";
import { bolsaDeComercioDelChacoTenant } from "./code.ts";
import { bolsaDeComercioDelChacoFullLabel } from "./full-label.ts";
import { bolsaDeComercioDelChacoLabel } from "./label.ts";

class BolsaDeComercioDelChacoTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return bolsaDeComercioDelChacoTenant;
  }

  getPathLabel(): string | null {
    return "bolsa-de-comercio-del-chaco";
  }

  getLabel(): string {
    return bolsaDeComercioDelChacoLabel;
  }

  getFullLabel(): string {
    return bolsaDeComercioDelChacoFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71107685-5";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  getMavAgentId(): number | null {
    return bolsaDeComercioDelChacoMavAgentId;
  }
}

const bolsaDeComercioDelChacoTenantHandler =
  new BolsaDeComercioDelChacoTenantHandler();

export default bolsaDeComercioDelChacoTenantHandler;
