/* DON'T EDIT THIS FILE: edit original and run build again */ import { isCompanyInlineEntity } from "../../credit/entity/inline-entity.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import { notCondition } from "../../framework/live-form/combine-conditions.ts";
import { LiveFieldConditionCallback } from "../../framework/live-form/types.ts";
import { getTenantHandler } from "../../framework/tenant-collection/tenant.ts";

export const onlyPeople: LiveFieldConditionCallback<FolderLiveData> = ({
  liveData: { recordValue },
}) => isCompanyInlineEntity(notNull(recordValue)) === false;

export const onlyCompanies: LiveFieldConditionCallback<FolderLiveData> = ({
  liveData: { recordValue },
}) => isCompanyInlineEntity(notNull(recordValue)) === true;

export const tenantUsesUruguayLaw = (tenant: string) =>
  !!getTenantHandler(tenant).getOnboardingOptions()?.usesUruguayLaw();

export const onlyUruguay: LiveFieldConditionCallback<FolderLiveData> = ({
  liveData: { baseFolder },
}) => tenantUsesUruguayLaw(notNull(baseFolder).tenant);

export const notUruguay: LiveFieldConditionCallback<FolderLiveData> =
  notCondition(onlyUruguay);

export const onlyUruguayCompanies: LiveFieldConditionCallback<
  FolderLiveData
> = (param) => onlyUruguay(param) && onlyCompanies(param);

export const onlyUruguayPeople: LiveFieldConditionCallback<FolderLiveData> = (
  param
) => onlyUruguay(param) && onlyPeople(param);
