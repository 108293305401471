/* DON'T EDIT THIS FILE: edit original and run build again */ import { InputElement } from "../../../credit/ui/form/input-element.tsx";
import { LiveTextViewer } from "../../../framework/live-form/ui/text-viewer.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
} from "../../../framework/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../framework/live-form/ui/use-live-after-blur-text-input.tsx";
import { phoneLiveFieldType } from "./type.ts";

const PhoneInput = (props: {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  autoComplete?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
}) => <InputElement type="tel" {...props} />;

const LivePhoneInput: LiveFieldInput<unknown> = (fieldInfo) => (
  <PhoneInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const phoneLiveFieldRenderers: LiveFieldRenderer<unknown> = {
  type: phoneLiveFieldType,
  Input: LivePhoneInput,
  Viewer: LiveTextViewer,
};
