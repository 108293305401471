// This file is generated based on Triggers.ts files, don't edit
/* eslint-disable max-lines */
import { lazyComponent } from "../framework/ui-app/lazy-component.tsx"
export const appRoutes = [
  {
    path: "/carpetas",
    Component: lazyComponent(
      async () => (await import("../credit/common-folder-listing/folder-page.front.tsx")).default
    ),
    requires: {"permission":"canListLines","account":true,"tenant":true},
    hardcodedProps: {"selectedPath":"/carpetas"}
  },
  {
    path: "/enviadas",
    Component: lazyComponent(
      async () => (await import("../credit/common-folder-listing/folder-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessGrantedLines","account":true,"tenant":true},
    hardcodedProps: {"selectedPath":"/enviadas"}
  },
  {
    path: "/inbox",
    Component: lazyComponent(
      async () => (await import("../credit/common-folder-listing/folder-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true},
    hardcodedProps: {"selectedPath":"/inbox"}
  },
  {
    path: "/panel-de-control",
    Component: lazyComponent(
      async () => (await import("../credit/control-panel/control-panel-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessControlPanel","account":true,"tenant":true}
  },
  {
    path: "/info-completa/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/line-detail-pages/line-full-info-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessLinesSeparatePagesView","account":true,"tenant":true}
  },
  {
    path: "/info-completa/:lineId/:path/*",
    Component: lazyComponent(
      async () => (await import("../credit/line-detail-pages/line-full-info-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessLinesSeparatePagesView","account":true,"tenant":true}
  },
  {
    path: "/vista-de-presentacion/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/line-detail-pages/presentation-view-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessLinesPresentationViewPage","account":true,"tenant":true}
  },
  {
    path: "/carga-de-informacion/:dataLoadType/:lineId/:path/*",
    Component: lazyComponent(
      async () => (await import("../credit/line-detail-pages/data-load-page-redirect.front.tsx")).default
    ),
    requires: {"permission":"canAccessLineDataLoadPages","account":true,"tenant":true},
  isMatchfinSpecific: true
  },
  {
    path: "/analisis-preliminar/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/line-detail-pages/risk-preview-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessRiskPreview","account":true,"tenant":true},
  isMatchfinSpecific: true
  },
  {
    path: "/carpetas/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/line-redirect/line-full-page-redirect.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/integraciones/lufe/:cuit",
    Component: lazyComponent(
      async () => (await import("../credit/lufe-cuit-page/lufe-cuit-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/integraciones/lufe",
    Component: lazyComponent(
      async () => (await import("../credit/lufe-listing/lufe-listing-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/marketplace",
    Component: lazyComponent(
      async () => (await import("../credit/marketplace-common-listing/marketplace-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessMarketplace","account":true,"tenant":true},
    hardcodedProps: {"selectedPath":"/marketplace"}
  },
  {
    path: "/marketplace/informes-sgrs",
    Component: lazyComponent(
      async () => (await import("../credit/marketplace-common-listing/marketplace-page.front.tsx")).default
    ),
    requires: {"permission":"canViewSgrReport","account":true,"tenant":true},
    hardcodedProps: {"selectedPath":"/marketplace/informes-sgrs"}
  },
  {
    path: "/marketplace/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/marketplace-line-page/marketplace-line-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessMarketplace","account":true,"tenant":true}
  },
  {
    path: "/marketplace-link/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/marketplace/marketplace-redirect.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/historial-analisis-quant/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/quant-analysis-page/quant-analysis-journal.front.tsx")).default
    ),
    requires: {"permission":"canViewQuantAnalysisResult","account":true,"tenant":true}
  },
  {
    path: "/analisis-quant/:lineId/:id",
    Component: lazyComponent(
      async () => (await import("../credit/quant-analysis-page/quant-analysis-page.front.tsx")).default
    ),
    requires: {"permission":"canViewQuantAnalysisResult","account":true,"tenant":true}
  },
  {
    path: "/analisis-quant/:lineId",
    Component: lazyComponent(
      async () => (await import("../credit/quant-analysis-page/quant-analysis-page.front.tsx")).default
    ),
    requires: {"permission":"canViewQuantAnalysisResult","account":true,"tenant":true}
  },
  {
    path: "/matrices-de-riesgo",
    Component: lazyComponent(
      async () => (await import("../credit/risk-matrix/risk-matrix-listing-page.front.tsx")).default
    ),
    requires: {"permission":"canViewRiskMatrix","account":true,"tenant":true}
  },
  {
    path: "/matriz-de-riesgo/:riskMatrixId/:path/*",
    Component: lazyComponent(
      async () => (await import("../credit/risk-matrix/risk-matrix-info-page.front.tsx")).default
    ),
    requires: {"permission":"canViewRiskMatrix","account":true,"tenant":true}
  },
  {
    path: "/informes-sgrs/:sgrReportId/:path/*",
    Component: lazyComponent(
      async () => (await import("../credit/sgrs-reports/sgr-report-info-page.front.tsx")).default
    ),
    requires: {"permission":"canEditSgrReport","account":true,"tenant":true}
  },
  {
    path: "/informes-sgrs/vista-de-presentacion/:reportId/:fileId",
    Component: lazyComponent(
      async () => (await import("../credit/sgrs-reports/presentation-view-page.front.tsx")).default
    ),
    requires: {"permission":"canViewSgrReport","account":true,"tenant":true}
  },
  {
    path: "/informes-sgrs",
    Component: lazyComponent(
      async () => (await import("../credit/sgrs-reports/listing/sgrs-reports-listing-page.front.tsx")).default
    ),
    requires: {"permission":"canEditSgrReport","account":true,"tenant":true}
  },
  {
    path: "/about",
    Component: lazyComponent(
      async () => (await import("../framework/about/about-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/alycs",
    Component: lazyComponent(
      async () => (await import("../framework/alyc/alyc-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/_wait",
    Component: lazyComponent(
      async () => (await import("../framework/auth-admin-tenant-select/wait-page.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/mi-perfil",
    Component: lazyComponent(
      async () => (await import("../framework/auth-edit-profile-page/profile-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/mi-perfil/:dir",
    Component: lazyComponent(
      async () => (await import("../framework/auth-edit-profile-page/profile-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/mi-perfil/error",
    Component: lazyComponent(
      async () => (await import("../framework/auth-edit-profile-page/error-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/recuperar-cuenta",
    Component: lazyComponent(
      async () => (await import("../framework/auth-forgot-password-page/forgot-password-page.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/login",
    Component: lazyComponent(
      async () => (await import("../framework/auth-login-page/normal-user-login-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/relogin",
    Component: lazyComponent(
      async () => (await import("../framework/auth-relogin/relogin-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/logout",
    Component: lazyComponent(
      async () => (await import("../framework/auth-ui/logout-page.front.ts")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/bancos",
    Component: lazyComponent(
      async () => (await import("../framework/bank-user/bank-user-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/camaras",
    Component: lazyComponent(
      async () => (await import("../framework/chamber/chamber-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/chat",
    Component: lazyComponent(
      async () => (await import("../framework/chat/chat-list-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessChat","account":true,"tenant":true}
  },
  {
    path: "/chat/:conversationId",
    Component: lazyComponent(
      async () => (await import("../framework/chat/chat-line-sharing-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessChat","account":true,"tenant":true}
  },
  {
    path: "/contrapartes",
    Component: lazyComponent(
      async () => (await import("../framework/counterparties-pages/list-user-companies-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessCounterpartiesPage","account":true,"tenant":true}
  },
  {
    path: "/contrapartes/historial",
    Component: lazyComponent(
      async () => (await import("../framework/counterparties-pages/counterparties-log-page.front.tsx")).default
    ),
    requires: {"permission":"canViewCounterpartiesLog","account":true,"tenant":true}
  },
  {
    path: "/inversores",
    Component: lazyComponent(
      async () => (await import("../framework/investors/investor-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/directorio",
    Component: lazyComponent(
      async () => (await import("../framework/list-all-users-page/list-all-users-page.front.tsx")).default
    ),
    requires: {"permission":"canListAllUsers","account":true,"tenant":true},
  isMatchfinSpecific: true
  },
  {
    path: "/permisos",
    Component: lazyComponent(
      async () => (await import("../framework/permissions-page/permissions-page.front.tsx")).default
    ),
    requires: {"permission":"canDebugPermissions","account":true,"tenant":true},
  isMatchfinSpecific: true
  },
  {
    path: "/productores",
    Component: lazyComponent(
      async () => (await import("../framework/producers/producer-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/distribuidores",
    Component: lazyComponent(
      async () => (await import("../framework/producers/distributor-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/solicitud-de-adhesion-de-productores",
    Component: lazyComponent(
      async () => (await import("../framework/producers/producers-conditions.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/",
    Component: lazyComponent(
      async () => (await import("../framework/redirect/redirect-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/",
    Component: lazyComponent(
      async () => (await import("../framework/redirect/redirect-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/manual-de-roles",
    Component: lazyComponent(
      async () => (await import("../framework/roles-page/roles-description-page.front.tsx")).default
    ),
    requires: {"permission":"canAccessRolesDescription","account":true,"tenant":true}
  },
  {
    path: "/ajustes",
    Component: lazyComponent(
      async () => (await import("../framework/settings-section/settings-listing-page.front.tsx")).default
    ),
    requires: {"permission":"canEditTenantSettings","account":true,"tenant":true}
  },
  {
    path: "/ajustes/:dir",
    Component: lazyComponent(
      async () => (await import("../framework/settings-section/settings-listing-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/sgrs",
    Component: lazyComponent(
      async () => (await import("../framework/sgrs/sgr-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/pendiente-de-aprobacion",
    Component: lazyComponent(
      async () => (await import("../framework/sign-up/pending-approval-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/registro",
    Component: lazyComponent(
      async () => (await import("../framework/sign-up/end-client-user-sign-up-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/financiamiento",
    Component: lazyComponent(
      async () => (await import("../framework/tenant-endclient-sign-up/landing.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/registro-empresas",
    Component: lazyComponent(
      async () => (await import("../framework/tenant-endclient-sign-up/sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/expo-agro-2025/financiamiento",
    Component: lazyComponent(
      async () => (await import("../framework/tenant/argenpymes/campaigns/expo-agro-2025/landing.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/expo-agro-2025/registro-empresas",
    Component: lazyComponent(
      async () => (await import("../framework/tenant/argenpymes/campaigns/expo-agro-2025/sign-up-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/expo-agro-2025/es-cliente",
    Component: lazyComponent(
      async () => (await import("../framework/tenant/argenpymes/campaigns/expo-agro-2025/is-client.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/expo-agro-2025/gestionar-linea",
    Component: lazyComponent(
      async () => (await import("../framework/tenant/argenpymes/campaigns/expo-agro-2025/advise-line.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/condiciones-de-uso",
    Component: lazyComponent(
      async () => (await import("../framework/terms-of-use/terms-of-use-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/directo/:tokenCode",
    Component: lazyComponent(
      async () => (await import("../framework/token/token-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/urls",
    Component: lazyComponent(
      async () => (await import("../framework/urls-page/urls-page.front.tsx")).default
    ),
    requires: {"permission":"canListUrls","account":true,"tenant":true},
  isMatchfinSpecific: true
  },
  {
    path: "/usuarios",
    Component: lazyComponent(
      async () => (await import("../framework/users-page/users-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/usuarios/:uid/:dir",
    Component: lazyComponent(
      async () => (await import("../framework/users-profile/profile-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/usuarios/:uid/",
    Component: lazyComponent(
      async () => (await import("../framework/users-profile/profile-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/usuarios/:uid/error",
    Component: lazyComponent(
      async () => (await import("../framework/users-profile/error-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/invitacion/:inviteId",
    Component: lazyComponent(
      async () => (await import("../framework/users/invite-users/accept-invite-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/cadenas-de-valor",
    Component: lazyComponent(
      async () => (await import("../framework/value-chain/value-chain-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/onboardings/:onboardingId/:path/*",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-full-info-page/onboarding-full-info-page.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/onboardings",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-list-or-redirect/onboarding-list-or-redirect.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true},
    redirectOnNoAccount: "/onboardings/crear-cuenta"
  },
  {
    path: "/vinculaciones",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-redirect/onboarding-list-page-redirect.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/onboardings/:onboardingId",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-redirect/onboarding-redirect.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/vinculaciones/:onboardingId",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-redirect/onboarding-redirect.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/vinculaciones/:onboardingId/:path/*",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-redirect/onboarding-redirect.front.tsx")).default
    ),
    requires: {"account":true,"tenant":true}
  },
  {
    path: "/onboardings/crear-cuenta",
    Component: lazyComponent(
      async () => (await import("../onboarding/onboarding-sign-up/onboarding-sign-up.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/ordenes",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/order-routing-dashboard.front.tsx")).default
    ),
    requires: {"permission":"canViewOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/ordenes-live",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/orders-listing-page.front.tsx")).default
    ),
    requires: {"permission":"canViewOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/ordenes-historico",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/historical-orders-listing.front.tsx")).default
    ),
    requires: {"permission":"canViewOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/recreate-order/:orderId",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/recreate-order-page.front.tsx")).default
    ),
    requires: {"permission":"canViewOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/editar-orden/:orderId",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/edit-order-page.front.tsx")).default
    ),
    requires: {"permission":"canCreateOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/crear-orden",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/create-order-page.front.tsx")).default
    ),
    requires: {"permission":"canCreateOrderRouting","account":true,"tenant":true}
  },
  {
    path: "/validar-orden/:orderId/:statusLabel",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/validate-order-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },
  {
    path: "/ordenes/client-agreement/:agreementType",
    Component: lazyComponent(
      async () => (await import("../orders/order-routing/front/client-agreement/client-agreement-page.front.tsx")).default
    ),
    requires: {"account":false,"tenant":false}
  },

];
