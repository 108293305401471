/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canChangeToAllowedTenant,
  canChangeToAnyTenant,
  canEditSemaphoreSettings,
  canEditTenantSettings,
  canSeeOtherEndClients,
  canViewSemaphoreSettings,
  canViewTenantSettings,
} from "./permissions.ts";

const tenantPermissions: PermissionCollection = {
  namespace: "tenant",
  permissions: [
    {
      name: canViewTenantSettings,
      description: "Puede ver la configuracion del tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.matchfinAnalyst,
        Roles.auneSupport,
      ],
    },
    {
      name: canEditTenantSettings,
      description: "Puede editar la configuración del tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewSemaphoreSettings,
      description: "Puede ver la configuración del semáforo",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canEditSemaphoreSettings,
      description: "Puede editar la configuración del semáforo",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canChangeToAllowedTenant,
      description: "Puede navegar entre tenants permitidos",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.matchfinAnalyst,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canChangeToAnyTenant,
      description: "Puede navegar entre todos los tenants",
      roles: [Roles.admin],
    },
    {
      name: canSeeOtherEndClients,
      description:
        "Puede ver endClients finales dentro del tenant para los que no tiene acceso directo o está asginado",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.matchfinAnalyst,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
  ],
};

export default tenantPermissions;
