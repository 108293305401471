/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentToFulfill } from "../../../credit/autocomplete-files/types.ts";
import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { bindGarantiasTenant } from "./code.ts";
import { documentsToFulfill } from "./documents-to-fulfill.ts";
import { bindGarantiasFullLabel } from "./full-label.ts";
import { bindGarantiasLabel } from "./label.ts";

class BindGarantiasTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return bindGarantiasTenant;
  }

  getPathLabel(): string | null {
    return "bind";
  }

  getLabel(): string {
    return bindGarantiasLabel;
  }

  getFullLabel(): string {
    return bindGarantiasFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70860991-5";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }

  getDocumentsToFulfill(): DocumentToFulfill[] {
    return documentsToFulfill;
  }
}

const bindGarantiasTenantHandler = new BindGarantiasTenantHandler();

export default bindGarantiasTenantHandler;
