/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { extraNameFieldset } from "../../credit/entity-core-field/name-fields.ts";
import { InlineEntity } from "../../credit/entity/inline-entity-type.ts";
import { getCountryLabel } from "../../framework/country/countries.ts";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import { LiveRecord } from "../../framework/live-form/live-record.ts";
import { LiveFormSpecificFolder } from "../../framework/live-form/types.ts";
import { InlineEntityHandler } from "../../framework/live-form/ui/inline-entity-handler-type.ts";
import { RawLiveFormViewer } from "../../framework/live-form/ui/raw-live-form-viewer.tsx";
import { LiveFieldGetLiveFormInputFactory } from "../../framework/live-form/ui/types.ts";
import { getAge } from "../../framework/now/get-age.ts";
import { useIsInPdf } from "../../onboarding/pdf-wrapper/context.ts";
import { Div } from "../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { InlineEntityTypeIcon } from "./inline-entity-type-icon.tsx";

export const InlineEntityViewer = ({
  getLiveFormInputFactory,
  inlineEntity,
  includeName,
  includeExtraNameFields = includeName,
  includeDateOfBirth = false,
  baseFolder,
  specificFolder,
  inlineEntityHandler,
}: {
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory<FolderLiveData>;
  inlineEntity: Partial<InlineEntity>;
  includeName: boolean;
  includeExtraNameFields?: boolean;
  includeDateOfBirth?: boolean;
  baseFolder: BaseFolder;
  specificFolder?: LiveFormSpecificFolder;
  inlineEntityHandler: InlineEntityHandler;
}) => {
  const isInPdf = useIsInPdf();
  const { country, entityName, dateOfBirth, cuit, foreignIdNumber } =
    inlineEntity;
  const icon = isInPdf ? null : (
    <InlineEntityTypeIcon className="me-2" inlineEntity={inlineEntity} />
  );
  const liveRecord = new LiveRecord(
    extraNameFieldset,
    {
      baseFolder,
      specificFolder,
      recordValue: inlineEntity,
      inlineEntityHandler,
    },
    false,
    false
  );
  return (
    <Div>
      <Div>
        {includeName && entityName ? entityName : null}
        {includeDateOfBirth && dateOfBirth
          ? " (" + getAge(dateOfBirth) + " años)"
          : null}
      </Div>
      {cuit ? (
        <Div className={(isInPdf ? "" : "align-items-center ") + "d-flex"}>
          {icon}
          <Div className="me-2">{cuit}</Div>
        </Div>
      ) : (
        <>
          <Div className={(isInPdf ? "" : "align-items-center ") + "d-flex"}>
            {icon}
            {country ? (
              <Div key="country" className="me-2">
                {getCountryLabel(country)}
              </Div>
            ) : null}
            {foreignIdNumber ? (
              <Div key="idNumber" className="me-2">
                {foreignIdNumber}
              </Div>
            ) : null}
          </Div>
          {includeExtraNameFields ? (
            <RawLiveFormViewer
              getLiveFormInputFactory={getLiveFormInputFactory}
              liveRecord={liveRecord}
              Widgets={{}}
              showLabels={false}
            />
          ) : null}
        </>
      )}
    </Div>
  );
};
