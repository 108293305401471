/* DON'T EDIT THIS FILE: edit original and run build again */ export function mapObject<
  TPropIn extends string,
  TIn,
  TPropOut extends string,
  TOut
>(
  obj: Record<TPropIn, TIn>,
  callback: ([key, value]: [TPropIn, TIn]) => [TPropOut, TOut]
): Record<string, TOut> {
  const out: Record<string, TOut> = {};
  for (const key in obj) {
    const [newKey, newValue] = callback([key, obj[key]]);
    out[newKey] = newValue;
  }
  return out;
}
