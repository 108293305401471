/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessOnboardingAdminSections,
  canAccessOnboardingLog,
  canAccessOnboardings,
  canAddCommentOnboarding,
  canAddOnboardings,
  canBackupOnboardings,
  canCalculateInvestmentProfiles,
  canChangeToAllStatuses,
  canCreateVideoSelfieRequests,
  canDeleteOnboardingComments,
  canDeleteVideoSelfies,
  canEditConfirmedInvestmentProfiles,
  canEditOnboardingsWithoutAssigneeOrProducer,
  canEditOtherProducerOnboardings,
  canEditOtherSalesOnboardings,
  canEditOtherUsersOnboardings,
  canEditSubmittedOnboardings,
  canListOnboardings,
  canViewOnboardingsWithCommonStatuses,
  canViewOnboardingsWithInfoRequestStatus,
  canViewOnboardingsWithInitialLoadStatus,
  canViewVideoSelfies,
} from "./permissions.ts";

const onboardingPermissions: PermissionCollection = {
  namespace: "onboarding",
  permissions: [
    {
      name: canAccessOnboardingAdminSections,
      description:
        "Puede acceder a las secciones administradoras de los Onboardings: Estado, Datos básicos, Renaper",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.auneSupport,
      ],
    },
    {
      name: canAccessOnboardingLog,
      description: "Puede acceder al registro de onboardings",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.auneSupport,
      ],
    },
    {
      name: canAccessOnboardings,
      description:
        "Tiene acceso a los Onboardings y a las notificaciones por nuevos dictámenes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.auneSupport,
      ],
    },
    {
      name: canAddCommentOnboarding,
      description:
        "Puede agregar un comentario en la sección Dictámenes de los Onboardings",
      roles: [
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canAddOnboardings,
      description: "Puede agregar nuevos Onboardings",
      roles: [
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.endClientUser,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.auneSupport,
      ],
    },
    {
      name: canChangeToAllStatuses,
      description:
        "Puede acceder a la sección Estado y modificar el estado a cualquier otro",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.auneSupport],
    },
    {
      name: canCreateVideoSelfieRequests,
      description: "Puede crear solicitudes de video-selfie",
      roles: [
        Roles.endClientUser,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.auneSupport,
      ],
    },
    {
      name: canDeleteVideoSelfies,
      description: "Puede eliminar video-selfies",
      roles: [
        Roles.complianceOfficer,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.auneSupport,
      ],
    },
    {
      name: canCalculateInvestmentProfiles,
      description:
        "Puede calcular el resultado de perfiles de inversión por puntaje",
      roles: [
        Roles.endClientUser,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.auneSupport,
      ],
    },
    {
      name: canEditConfirmedInvestmentProfiles,
      description: "Puede editar perfiles de inversión ya confirmados",
      roles: [Roles.complianceOfficer, Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canEditOtherProducerOnboardings,
      description:
        "Puede editar los Onboardings cargados por otros productores, que esten dentro del mismo tenant",
      roles: [Roles.headOfProducers],
    },
    {
      name: canEditOtherSalesOnboardings,
      description:
        "Puede editar los onboardings de otros comerciales, que esten dentro del mismo tenant",
      roles: [Roles.salesManager],
    },

    {
      name: canEditOnboardingsWithoutAssigneeOrProducer,
      description:
        "Puede editar Onboardings que no tengan comercial ni productor asociado",
      roles: [Roles.salesManager],
    },
    {
      name: canEditOtherUsersOnboardings,
      description:
        "Puede editar los Onboardings de otros usuarios, independientemente de su rol",
      roles: [Roles.tenantAdmin, Roles.admin, Roles.auneSupport],
    },
    {
      name: canEditSubmittedOnboardings,
      description: "Puede editar Onboardings ya enviados",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.auneSupport,
      ],
    },
    {
      name: canListOnboardings,
      description: "Puede ver en formato listado los Onboardings cargados",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewOnboardingsWithCommonStatuses,
      description:
        "Puede ver los Onboardings en estados comunes: todos excepto Carga en proceso o Solicitud de información",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.auneSupport,
      ],
    },

    {
      name: canViewOnboardingsWithInfoRequestStatus,
      description:
        "Puede ver los Onboardings en estado Solicitud de información",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.auneSupport,
      ],
    },

    {
      name: canViewOnboardingsWithInitialLoadStatus,
      description: "Puede ver los Onboardings en estado Carga en proceso",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.auneSupport,
      ],
    },
    {
      name: canViewVideoSelfies,
      description: "Puede ver los video-selfies de los Onboardings",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canDeleteOnboardingComments,
      description: "El usuario puede eliminar comentarios en Onboardings",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
    {
      name: canBackupOnboardings,
      description: "Puede hacer un backup de los onboardings de la cuenta",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.complianceOfficer,
        Roles.auneSupport,
      ],
    },
  ],
};

export default onboardingPermissions;
