/* DON'T EDIT THIS FILE: edit original and run build again */ import { EntityType } from "../../credit/gov-id-number/entity-type.ts";

export enum DocumentExtension {
  xlsx,
  docx,
}

export type DocumentToFulfill = {
  label: string;
  filename: string;
  field: string;
  path: string;
  type: DocumentExtension;
  availableData: boolean;
  entityType: EntityType;
};

export type XlsxFileData = Record<string, string | number>;

export type DocxFileData = Record<
  string,
  string | number | Array<object> | undefined | null
>;
