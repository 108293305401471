/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ricsaTenant } from "./code.ts";
import { ricsaFullLabel } from "./full-label.ts";
import { ricsaLabel } from "./label.ts";

class RicsaTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return ricsaTenant;
  }

  getPathLabel(): string | null {
    return "ricsa";
  }

  getLabel(): string {
    return ricsaLabel;
  }

  getFullLabel(): string {
    return ricsaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71480850-4";
  }

  getCnvRegistryId(): string | null {
    return "884";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const ricsaTenantHandler = new RicsaTenantHandler();

export default ricsaTenantHandler;
