/* DON'T EDIT THIS FILE: edit original and run build again */ import { companyTypeLabels } from "../../credit/background-check/company-types.ts";
import { entityFieldBusinessEntityType } from "../../credit/entity/inline-entity-type.ts";
import { SingleOptionLiveField } from "../../framework/live-form-core-types/single-option/live-field.ts";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import { LiveFieldConditionCallback } from "../../framework/live-form/types.ts";

export const getBusinessEntityTypeField = (
  label: string,
  condition: LiveFieldConditionCallback<FolderLiveData>
) =>
  new SingleOptionLiveField({
    label,
    field: entityFieldBusinessEntityType,
    required: true,
    valueOptions: companyTypeLabels,
    condition,
  });
