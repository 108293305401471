/* DON'T EDIT THIS FILE: edit original and run build again */ import { entityFieldEntityName } from "../../credit/entity/inline-entity-type.ts";
import { TextLiveField } from "../../framework/live-form-core-types/text/live-field.ts";
import { onlyCompanies } from "./condition.ts";

export const entityNameField = new TextLiveField({
  label: "Razón social",
  field: entityFieldEntityName,
  required: true,
  condition: onlyCompanies,
});
