/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveField } from "../../framework/live-form/live-field.ts";
import { LiveData } from "../../framework/live-form/types.ts";
import { extendUserInputs } from "./disallowed.ts";

const keepStringsOnly = (array: any[]): string[] =>
  array?.filter((value: any) => typeof value === "string") ?? [];

const getDisallowedValues = <TExtraLiveData>({
  context,
  liveData,
  disallow,
}: {
  context: LiveField<TExtraLiveData>[];
  liveData: LiveData<TExtraLiveData>;
  disallow: any;
}) => {
  const from = disallow?.fields;
  const out = keepStringsOnly(disallow?.values);
  if (from == null) {
    return extendUserInputs(out);
  }
  for (const liveField of context.filter((lineField) => {
    const field = lineField.getField();
    return field && from[field];
  })) {
    const parsedValues = liveField.getParsedInput(liveData);
    const inputValues = extendUserInputs(
      keepStringsOnly(Object.values(parsedValues))
    );
    out.push(...inputValues);
  }
  return extendUserInputs(out);
};

export const getPasswordScore = <TExtraLiveData>({
  value,
  liveData,
  scorers,
  disallow,
  context,
}: {
  value: any;
  liveData: LiveData<TExtraLiveData>;
  scorers: any;
  disallow: any;
  context: LiveField<TExtraLiveData>[];
}) => {
  if (!(scorers?.sync instanceof Function)) {
    throw Error("Password setter rendered without a scorer.");
  }
  if (!value) {
    return { score: null, feedback: null };
  }
  return scorers.sync(
    value,
    getDisallowedValues({
      liveData,
      disallow,
      context,
    })
  );
};

export const getPasswordScoreAsync = async <TExtraLiveData>({
  value,
  liveData,
  disallow,
  scorers,
  context,
}: {
  value: any;
  liveData: LiveData<TExtraLiveData>;
  disallow: any;
  scorers: any;
  context: LiveField<TExtraLiveData>[];
}) => {
  if (!(scorers?.async instanceof Function)) {
    throw Error("Password setter rendered without a scorer.");
  }
  if (!value) {
    return { score: null, feedback: null };
  }
  return scorers.async(
    value,
    getDisallowedValues({
      liveData,
      disallow,
      context,
    })
  );
};
