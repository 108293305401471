/* DON'T EDIT THIS FILE: edit original and run build again */ import { ListRow } from "../../../credit/list-section/list-row.tsx";
import {
  AttachmentItem,
  AttachmentItemMap,
} from "../../../framework/attachment-ui/attachment-item.ts";
import { SavingFileInputButton } from "../../../framework/attachment-ui/saving-file-input-button.tsx";
import { SingleFileItemWidget } from "../../../framework/attachment-ui/single-file-item-widget.tsx";
import { generateId } from "../../../framework/core/id.ts";
import { notNull } from "../../../framework/core/not-null.ts";
import { FolderLiveData } from "../../../framework/live-form-folder-live-data/folder-live-data.tsx";
import { useLiveImmediateInput } from "../../../framework/live-form/ui/live-immediate-input.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../../framework/live-form/ui/types.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  MultipleFileLiveField,
  getSortedMultipleFileItems,
} from "./live-field.ts";
import { multipleFileLiveFieldType } from "./type.ts";

const AttachmentList = ({
  items,
  onRemoveClick,
}: {
  items: AttachmentItemMap;
  onRemoveClick?: (item: AttachmentItem) => void;
}) => {
  return (
    <Div>
      {getSortedMultipleFileItems(items).map((item: any) => (
        <ListRow
          key={item.id}
          onRemoveClick={onRemoveClick ? () => onRemoveClick(item) : undefined}
          items={[
            <SingleFileItemWidget
              key="attachment"
              attachment={item.attachment}
            />,
          ]}
        />
      ))}
    </Div>
  );
};

const Uploader = ({
  addAttachments,
  uid,
  uploadLabel,
  disabled,
}: {
  addAttachments: (newAttachments: AttachmentItemMap) => void;
  uid: string;
  uploadLabel: string;
  disabled: boolean;
}) => {
  const [uploading, setUploading] = useState(false);

  return (
    <div className="mb-4">
      {uploading ? (
        <Spinner animation="border" variant="gray40" children="" />
      ) : null}
      <SavingFileInputButton
        className="mb-2"
        onUpload={(attachments) => {
          const tmp: AttachmentItemMap = {};
          for (const attachment of attachments) {
            const id = generateId();
            tmp[id] = {
              id,
              attachment,
              time: new Date().toISOString(),
            };
          }
          addAttachments(tmp);
        }}
        disabled={disabled}
        onUploadingChange={setUploading}
        uploadLabel={uploadLabel}
        uid={uid}
      />
    </div>
  );
};

const Input: LiveFieldInput<FolderLiveData> = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField() as MultipleFileLiveField;
  const { setValue, value, disabled } = useLiveImmediateInput(fieldInfo);
  const addAttachments = (newFilesObj: AttachmentItemMap) =>
    setValue({ ...(value || {}), ...newFilesObj });
  return (
    <>
      {
        <Uploader
          addAttachments={addAttachments}
          uid={notNull(liveCell.getLiveData()?.specificFolder?.ownerUid)}
          uploadLabel={liveField.getUploadLabel()}
          disabled={disabled}
        />
      }
      <AttachmentList
        items={value || {}}
        onRemoveClick={async (item) => {
          if (!window.confirm("¿Eliminar?")) {
            return;
          }
          const { [item.id]: _, ...rest } = value ?? {};
          setValue(rest);
        }}
      />
    </>
  );
};

const Viewer: LiveFieldViewer<FolderLiveData> = ({ liveCell }) => {
  const fieldValue = (liveCell.getValue() || {}) as AttachmentItemMap;
  return <AttachmentList items={fieldValue} />;
};

export const multipleFileLiveFieldRenderers: LiveFieldRenderer<FolderLiveData> =
  {
    type: multipleFileLiveFieldType,
    Input,
    Viewer,
  };
