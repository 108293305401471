/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canAccessRedlinkData } from "./permissions.ts";

const redLinkPermissions: PermissionCollection = {
  namespace: "onboarding",
  permissions: [
    {
      name: canAccessRedlinkData,
      description: "Puede acceder a la informacion de RedLink",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.auneSupport,
      ],
    },
  ],
};

export default redLinkPermissions;
