/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveField } from "./live-field.ts";
import { LiveData } from "./types.ts";

/**
 * A field with data in a live form.
 */
export class LiveCell<TExtraLiveData> {
  constructor(
    private liveField: LiveField<TExtraLiveData>,
    private liveData: LiveData<TExtraLiveData>,
    private context: LiveField<TExtraLiveData>[],
    private showErrors: boolean,
    private formDisabled: boolean
  ) {}

  getLiveField() {
    return this.liveField.getRealField();
  }

  getLiveData() {
    return this.liveData;
  }

  getContext() {
    return this.context;
  }

  showOptionalIndicator() {
    return (
      !this.liveField.hideOptionalIndicator() &&
      !this.liveField.isRequired(this.liveData)
    );
  }

  getError() {
    if (!this.showErrors) {
      return null;
    }
    return this.liveField.getError(this.liveData, this.context);
  }

  getThisFieldError() {
    if (!this.showErrors) {
      return null;
    }
    return this.liveField.getThisFieldError(this.liveData, this.context);
  }

  isRequired() {
    return this.liveField.isRequired(this.liveData);
  }

  isDisabled() {
    return this.formDisabled || this.liveField.isDisabled();
  }

  getValue() {
    return this.liveField.getValue(this.liveData);
  }

  getShowErrors() {
    return this.showErrors;
  }
}
