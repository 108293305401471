/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { shillingCapitalTenant } from "./code.ts";
import { shillingCapitalFullLabel } from "./full-label.ts";
import { shillingCapitalLabel } from "./label.ts";
import { shillingCapitalOnboardingOptions } from "./shilling-capital-onboarding-options.ts";

class ShillingCapitalTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_shilling.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return shillingCapitalTenant;
  }

  getPathLabel(): string | null {
    return "shilling";
  }

  getLabel(): string {
    return shillingCapitalLabel;
  }

  getFullLabel(): string {
    return shillingCapitalFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return shillingCapitalOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@shillingcapitalmarket.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "clientes@shillingcapitalmarket.com.ar";
  }

  getCuit(): string {
    return "30-71853928-1";
  }

  getCnvRegistryId(): string | null {
    return "2367";
  }

  getColors(): ColorScheme {
    return {
      primary: "#FDCB52",
      secondary: "#907434",
      tertiary: "#FDCB52",
    };
  }
}

const shillingCapitalTenantHandler = new ShillingCapitalTenantHandler();

export default shillingCapitalTenantHandler;
