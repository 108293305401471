/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentHeaderParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseSgrOnboardingOptions } from "../../../framework/tenant-handlers/base-sgr-onboarding-options.ts";
import { FunctionComponent } from "react";

class SgrOnboardingOptions extends BaseSgrOnboardingOptions {
  async getDocumentHeader(): Promise<FunctionComponent<DocumentHeaderParams>> {
    return (await import("../../../framework/dependency/forbidden-import.front.ts")).default;
  }
}

export const sgrOnboardingOptions = new SgrOnboardingOptions();
