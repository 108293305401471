/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";
import { EntityType } from "../../../credit/gov-id-number/entity-type.ts";

export const documentsToFulfill: DocumentToFulfill[] = [
  {
    label: "Anexo",
    filename: "1-Anexo.docx",
    field: "1-anexo-docx",
    path: "/sgr-documentation/argenpymes/1-Anexo.docx",
    type: DocumentExtension.docx,
    availableData: true,
    entityType: EntityType.Any,
  },
  {
    label: "Deudas",
    filename: "2-Deudas.docx",
    field: "2-deudas-docx",
    path: "/sgr-documentation/argenpymes/2-Deudas.docx",
    type: DocumentExtension.docx,
    availableData: false,
    entityType: EntityType.Any,
  },
  {
    label: "Reseña",
    filename: "4-Reseña.docx",
    field: "4-resena-docx",
    path: "/sgr-documentation/argenpymes/4-Reseña.docx",
    type: DocumentExtension.docx,
    availableData: false,
    entityType: EntityType.Any,
  },
  {
    label: "Ventas",
    filename: "5-Ventas.docx",
    field: "5-ventas-docx",
    path: "/sgr-documentation/argenpymes/5-Ventas.docx",
    type: DocumentExtension.docx,
    availableData: true,
    entityType: EntityType.Any,
  },
  {
    label: "Agro",
    filename: "agro.xlsx",
    field: "agro-xlsx",
    path: "/sgr-documentation/argenpymes/agro.xlsx",
    type: DocumentExtension.xlsx,
    availableData: false,
    entityType: EntityType.Any,
  },
];
