/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  InlineEntity,
  entityFieldEntityName,
  entityFieldGender,
} from "../../credit/entity/inline-entity-type.ts";
import { Jsonable } from "../../framework/core/jsonable.ts";

export type BackgroundCheckAmbiguousMatch = {
  [entityFieldEntityName]: string;
  [entityFieldGender]: string;
  idNumber: string;
  documentType: "cuit" | "dni";
};

export type BackgroundCheckFlatVars = Record<
  string,
  { value: Jsonable; type: string }
>;

export type OkNosisContent = {
  time: string;
  flatVars: BackgroundCheckFlatVars;
};

export type AmbiguousNosisContent = {
  time: string;
  matches: BackgroundCheckAmbiguousMatch[];
};

export type ErrorNosisContent = {
  error: string;
};

export type SimpleNosisDocContent =
  | OkNosisContent
  | AmbiguousNosisContent
  | ErrorNosisContent
  | null;

export type BackgroundCheckResponseType = "found" | "ambiguous" | "error";

export const entityFoundResponse: BackgroundCheckResponseType = "found";
export const entityAmbiguousResponse: BackgroundCheckResponseType = "ambiguous";
export const entityErrorResponse: BackgroundCheckResponseType = "error";

export type BackgroundCheckResponse = {
  type: BackgroundCheckResponseType;
  data?: InlineEntity;
  matches?: BackgroundCheckAmbiguousMatch[];
  error?: string;
};
