/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { sanCristobalTenant } from "./code.ts";
import { sanCristobalFullLabel } from "./full-label.ts";
import { sanCristobalLabel } from "./label.ts";
import { sanCristobalOnboardingOptions } from "./san-cristobal-onboarding-options.ts";

class SanCristobalTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_sancristobal.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return sanCristobalTenant;
  }

  getPathLabel(): string | null {
    return "sanCristobal";
  }

  getLabel(): string {
    return sanCristobalLabel;
  }

  getFullLabel(): string {
    return sanCristobalFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return sanCristobalOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "compliance@sancristobalsf.com.ar";
  }

  getCuit(): string {
    return "30-69363784-4";
  }

  getCnvRegistryId(): string | null {
    return "2197";
  }

  getColors(): ColorScheme {
    return {
      primary: "#adb0b3",
      secondary: "#606366",
      tertiary: "#FFFFFF",
    };
  }
}

const sanCristobalTenantHandler = new SanCristobalTenantHandler();

export default sanCristobalTenantHandler;
