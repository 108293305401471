/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canRunBeancounter, canViewBeancounter } from "./permissions.ts";

const beancounterPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canViewBeancounter,
      description:
        "Puede ver el estado de la extracción de información de un documento de balance",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canRunBeancounter,
      description:
        "Puede ejecutar extracciones de información de documentos de balance",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.complianceOfficer,
      ],
    },
  ],
};

export default beancounterPermissions;
