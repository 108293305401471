/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData } from "../../../framework/live-form/types.ts";
import {
  BaseTextLiveField,
  BaseTextLiveFieldSpec,
} from "../../../framework/live-form/ui/base-text-live-field-spec.tsx";
import { tokenLiveFieldType } from "./type.ts";

export type TokenLiveFieldSpec<TExtraLiveData> =
  BaseTextLiveFieldSpec<TExtraLiveData> & {
    length: number;
  };

export class TokenLiveField<
  TExtraLiveData
> extends BaseTextLiveField<TExtraLiveData> {
  private length: number;

  constructor(spec: TokenLiveFieldSpec<TExtraLiveData>) {
    super(spec, tokenLiveFieldType);
    this.length = spec.length;
  }

  isEmpty(liveData: LiveData<TExtraLiveData>): boolean {
    return !this.getValue(liveData);
  }

  getLength(): number {
    return this.length;
  }

  getError(liveData: LiveData<TExtraLiveData>): string | null {
    const value = this.getValue(liveData) as string;
    if (value.length !== this.getLength()) {
      return "El token debe tener largo " + this.length + ".";
    }
    return null;
  }

  getTypeDefaultValue(): string {
    return "";
  }
}
